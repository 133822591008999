import { Stack } from "@mui/material"
import Dropdown from "../../../components/dropdown/dropdown"
import InputTextField from "../../../components/textfield/inputTextField"
import BaseButton from "../../../components/button/button"
import { useContext, useState } from "react"
import useScreenType from "react-screentype-hook"
import { AlertDialogContext, UserInfoContext } from "../../../App"
import useSnackbar from "../../../components/snackBar/useSnackbar"
import FeathersClient from '../../../feathers/feathersClient'
import Utils from "../../../utils/utils"

export default function AddCustomerBankAccountFormPopup({ account, user, result, onClosed }) {
    const screenType = useScreenType()

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const snackBar = useSnackbar()

    //States
    const [editAccount, setEditAccount] = useState(account)

    return (
        <Stack spacing='20px' sx={{ margin: '10px', minWidth: screenType.isMobile ? null : '400px' }}>
            <Dropdown title='Bank Name' value={editAccount?.withdrawalBank ?? ''} items={result?.withdrawalBanks} onSelectChanged={(value) => {
                setEditAccount(editAccount => ({
                    ...editAccount,
                    withdrawalBank: value
                }))
            }} />
            <InputTextField
                title='Bank Account Number'
                placeholder='Enter Bank Account Number'
                value={editAccount?.withdrawalBankAccountNumber}
                onTextChanged={(value) => {
                    setEditAccount(editAccount => ({
                        ...editAccount,
                        withdrawalBankAccountNumber: value
                    }))
                }} />
            {
                (Utils.shared.supportsBSBNumber(userInfo)) ?
                    (
                        <InputTextField
                            title='BSB Number (Optional)'
                            placeholder='Enter BSB Number'
                            helperText='* For Certain Country Use Only'
                            value={editAccount?.bsbNumber}
                            onTextChanged={(value) => {
                                setEditAccount(editAccount => ({
                                    ...editAccount,
                                    bsbNumber: value
                                }))
                            }} />
                    ) : null
            }
            <BaseButton title='Submit' onTap={() => {
                let data = {
                    company: userInfo?.company._id,
                    user: user._id,
                    withdrawalBank: editAccount?.withdrawalBank,
                    withdrawalBankAccountNumber: editAccount?.withdrawalBankAccountNumber,
                    bsbNumber: editAccount?.bsbNumber,
                }

                if (editAccount?._id) {
                    //Edit
                    FeathersClient.patch('player-withdrawal-accounts', editAccount._id, data, null, setAlertDialog, (response) => {
                        snackBar.showMessage('Bank account updated successfully')
                        onClosed()
                    })
                } else {
                    //Add
                    FeathersClient.create('add-player-withdrawal-account', data, null, setAlertDialog, (response) => {
                        snackBar.showMessage('Bank account added successfully')
                        onClosed()
                    })
                }
            }} />
        </Stack>
    )
}