import { Stack, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, IconButton } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { StyledTableCell, StyledTableRow } from "../../components/dataGrid/tableGrid"
import { SwitchAccount } from "@mui/icons-material"
import useSnackbar from "../../components/snackBar/useSnackbar"

export default function CustomerGame({ user }) {
    const snackBar = useSnackbar()

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    //States
    const [list, setList] = useState([])

    //Ref
    const listRef = useRef(list)

    //DATA GRID METHODS
    const getRows = () => {
        return list.map((row, index) => {
            let updatedAt = row?.updatedAt ? Utils.shared.convertDateToString(Date.parse(row?.updatedAt), Constants.date_format_2) : '-'
            let game = row?.game?.label ?? '-'
            let gameCode = row?.game?.gameCode
            let userName = row?.userName ?? '-'
            let password = row?.password ?? '-'
            var shouldAllowChangeAccount = false

            if (row?.userName && row?.userName.length > 0 && row?.id) {
                if (gameCode === Constants.gameCodePussy888 ||
                    gameCode === Constants.gameCodeKiss918 ||
                    gameCode === Constants.gameCodeMega888 ||
                    gameCode === Constants.gameCodeEvo888 ||
                    gameCode === Constants.gameCode918KAYA) {
                    shouldAllowChangeAccount = true
                }
            }

            return (
                <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{game}</StyledTableCell>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{userName}</StyledTableCell>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{password}</StyledTableCell>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{updatedAt}</StyledTableCell>
                    <StyledTableCell align='right'>
                        {
                            shouldAllowChangeAccount ? (
                                <Tooltip title='Change Account'>
                                    <IconButton onClick={() => {
                                        setAlertDialog(alertDialog => ({
                                            ...alertDialog,
                                            title: `Change account for ${userName}`,
                                            description: 'Are you sure?',
                                            isConfirmation: true,
                                            onConfirmed: () => {
                                                FeathersClient.create('refresh-gameplayer-account', {
                                                    'gamesPlayers': row?._id
                                                }, null, setAlertDialog, (response) => {
                                                    snackBar.showMessage('Account changed successfully')
                                                    getList()
                                                })
                                            },
                                            isOpen: true
                                        }))
                                    }}>
                                        <SwitchAccount fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            ) : null
                        }
                    </StyledTableCell>
                </StyledTableRow>
            )
        }) ?? []
    }

    //API METHODS
    const getList = () => {
        FeathersClient.create('get-player-games-details', {
            'company': userInfo?.company?._id,
            'user': user._id,
        }, null, setAlertDialog, (response) => {
            let gamesPlayers = (response?.gamesPlayers ?? [])?.map((e, index) => {
                var temp = e

                if (!temp._id) {
                    temp._id = index
                }

                return temp
            })
            setList(gamesPlayers)
            listRef.current = gamesPlayers
        })
    }

    useEffect(() => {
        if (user) {
            getList()
        }
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Game</StyledTableCell>
                                        <StyledTableCell>Username</StyledTableCell>
                                        <StyledTableCell>Password</StyledTableCell>
                                        <StyledTableCell>Updated Date/Time</StyledTableCell>
                                        <StyledTableCell align="right">Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                        <StyledTableCell component="th" scope="row" align="center" colSpan={5}>
                                            No Record Found
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Stack>
            </div>
        )
    } else {
        return null
    }
}