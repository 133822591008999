import { useState } from "react"
import InputTextField from "./inputTextField"

export default function EditSequenceField({ index, originSequence, onSequenceChanged }) {
    const [sequence, setSequence] = useState(originSequence)

    return (
        <InputTextField value={sequence} type='number' fullWidth={false} onTextChanged={(value) => {
            onSequenceChanged(value)
            setSequence(value)
        }} />
    )
}