import client from './feathers'
import axios from "axios"
import Constants from '../constants'
import Utils from '../utils/utils'

class FeathersClient {
    constructor() {
        if (!FeathersClient.instance) {
            this.listenerKeys = {}
            FeathersClient.instance = this
        }

        return FeathersClient.instance
    }

    //AUTHENTICATE
    authenticate(userName, password, setUserInfo, onSuccess, onError, onCompletion = null) {
        const spinner = document.getElementById('spinner')
        spinner.style.display = 'block'

        client.authenticate({
            strategy: 'local-bo',
            userNameBO: userName,
            password: password,
        }).then(async (response) => {
            spinner.style.display = 'none'

            setUserInfo(response.user)

            onSuccess(response)
        }).catch(
            function (error) {
                spinner.style.display = 'none'

                const processedError = FeathersClient.checkErrorCode(error)

                onError(processedError)
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //REAUTHENTICATE
    reauthenticate(onSuccess, onError = null, setAlertDialog = null, setUserInfo = null, onCompletion = null) {
        client.authenticate().then((response) => {
            if (setUserInfo) {
                setUserInfo(response.user)
            }

            if (onSuccess) {
                onSuccess(response)
            }
        }).catch(
            function (error) {
                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (processedError.shouldKick) {
                    window.localStorage.removeItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`)
                }

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError)
                }
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //LOGOUT
    logout(onLoggedOut = null) {
        client.logout().finally(() => {
            if (onLoggedOut) {
                onLoggedOut()
            }
        })
    }

    //CREATE
    create(service, data, params = null, setAlertDialog, onSuccess = null, onError = null, showLoading = true, onCompletion = null) {
        const spinner = document.getElementById('spinner')

        if (showLoading && spinner) {
            spinner.style.display = 'block'
        }

        client.service(service).create(data, params).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                if (onSuccess) {
                    onSuccess(response)
                }
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (spinner) {
                spinner.style.display = 'none'
            }

            if (onCompletion) {
                onCompletion()
            }
        })
    }
    createREST(service, data, params = null, setAlertDialog, onSuccess = null, onError = null, showLoading = true, onCompletion = null) {
        const spinner = document.getElementById('spinner')

        if (showLoading) {
            spinner.style.display = 'block'
        }

        let token = Utils.shared.retrieveLocalStorage(Constants.feathersJWTKey)
        let url = `${Constants.baseURL}/${service}`
        axios.post(url, data, {
            headers: {
                authorization: token
            },
            params: params
        }).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                if (onSuccess) {
                    onSuccess(response)
                }
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                console.log(error)
                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //PATCH
    patch(service, objectId, data, params = null, setAlertDialog, onSuccess = null, onError = null, onCompletion = null, showLoading = true) {
        const spinner = document.getElementById('spinner')

        if (showLoading) {
            spinner.style.display = 'block'
        }

        client.service(service).patch(objectId, data, params).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                if (onSuccess) {
                    onSuccess(response)
                }
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (spinner) {
                spinner.style.display = 'none'
            }
            
            if (onCompletion) {
                onCompletion()
            }
        })
    }
    patchREST(service, objectId, data, params = null, setAlertDialog, onSuccess = null, onError = null, onCompletion = null, showLoading = true) {
        const spinner = document.getElementById('spinner')

        if (showLoading) {
            spinner.style.display = 'block'
        }

        let token = Utils.shared.retrieveLocalStorage(Constants.feathersJWTKey)
        let url = `${Constants.baseURL}/${service}/${objectId}`
        axios.patch(url, data, {
            headers: {
                authorization: token
            }
        }).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                if (onSuccess) {
                    onSuccess(response)
                }
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //REMOVE
    remove(service, objectId, params = null, setAlertDialog, onSuccess = null, onError = null, onCompletion = null, showLoading = true) {
        const spinner = document.getElementById('spinner')

        if (showLoading) {
            spinner.style.display = 'block'
        }

        client.service(service).remove(objectId, params).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                if (onSuccess) {
                    onSuccess(response)
                }
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //FIND
    find(service, params, setAlertDialog, onSuccess, onError = null, onCompletion = null, showLoading = true) {
        const spinner = document.getElementById('spinner')

        if (showLoading) {
            spinner.style.display = 'block'
        }
        client.service(service).find(params).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                onSuccess(response)
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //GET
    get(service, objectId, params = null, setAlertDialog, onSuccess, onError = null, onCompletion = null, showLoading = true) {
        const spinner = document.getElementById('spinner')

        if (showLoading) {
            spinner.style.display = 'block'
        }

        client.service(service).get(objectId, params).then(
            function (response) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                onSuccess(response)
            }
        ).catch(
            function (error) {
                if (spinner) {
                    spinner.style.display = 'none'
                }

                const processedError = FeathersClient.checkErrorCode(error)
                const shouldKick = processedError.shouldKick

                if (setAlertDialog) {
                    FeathersClient.showErrorDialog(error, setAlertDialog, shouldKick)
                }

                if (onError) {
                    onError(processedError, shouldKick)
                }
            }
        ).finally(() => {
            if (onCompletion) {
                onCompletion()
            }
        })
    }

    //GET USER
    async getUser() {
        let { user } = await client.get('authentication')
        return user
    }

    //LISTENERS
    listen(service, event, listenerId = '', listener, allowedNoOfListener = 1) {
        //Store the listener callback
        this.listenerKeys[`${service}${event}${listenerId}`] = listener

        //Check if socket has listener of this service
        let eventNames = client.service(service).listeners(event)
        if (eventNames.length < allowedNoOfListener) {
            //No listener added yet, begin adding listener
            client.service(service).on(event, (data) => {
                for (const [key, value] of Object.entries(this.listenerKeys)) {
                    if (key.indexOf(`${service}${event}`) > -1) {
                        this.listenerKeys[key](data)
                    }
                }
            })
        }
    }

    //ERROR CHECKING
    static checkErrorCode(error) {
        var tempError = error
        tempError.shouldKick = false

        const code = error.code
        const message = error.message

        switch (code) {
            case 408:
                //Connection timed out
                tempError.message = 'Connection timed out. Please try again.'
                break
            case 403:
            case 405:
            case 445:
                //Need to kick user to home page
                tempError.shouldKick = true
                break
            default:
                break
        }

        return tempError
    }

    checkErrorCode(error) {
        var tempError = error
        tempError.shouldKick = false

        const code = error.code
        const message = error.message

        switch (code) {
            case 408:
                //Connection timed out
                tempError.message = 'Connection timed out. Please try again.'
                break
            case 403:
            case 405:
            case 445:
                //Need to kick user to home page
                tempError.shouldKick = true
                break
            default:
                break
        }

        return tempError
    }

    static showErrorDialog(error, setAlertDialog, shouldKick = false) {
        if (shouldKick) {
            window.location.replace(`${window.location.protocol}`)
        } else {
            setAlertDialog(alertDialog => ({
                ...alertDialog,
                title: null,
                description: error.message,
                isOpen: true,
            }))
        }
    }

    showErrorDialog(error, setAlertDialog, shouldKick = false) {
        if (shouldKick) {
            window.location.replace(`${window.location.protocol}`)
        } else {
            setAlertDialog(alertDialog => ({
                ...alertDialog,
                title: null,
                description: error.message,
                isOpen: true,
            }))
        }
    }

    //Show/hide spinner
    static showLoading(show) {
        const spinner = document.getElementById('spinner')
        if (show) {
            spinner.style.display = 'block'
        } else {
            spinner.style.display = 'none'
        }
    }

    showLoading(show) {
        const spinner = document.getElementById('spinner')
        if (show) {
            spinner.style.display = 'block'
        } else {
            spinner.style.display = 'none'
        }
    }
}

const instance = new FeathersClient()
Object.freeze(instance)

export default instance
