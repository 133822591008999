import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AlertDialogContext } from '../../App';

function AlertDialog({open, title, message, isConfirmation, onClosed}) {
  const { alertDialog, setAlertDialog } = useContext(AlertDialogContext)

  const handleConfirm = (event, reason) => {
    var onConfirmed = () => {}
    if (typeof(alertDialog?.onConfirmed()) === 'function') {
      onConfirmed = alertDialog?.onConfirmed()
    }

    setAlertDialog(alertDialog => ({
      ...alertDialog,
      title: 'Error',
      closeButtonTitle: 'OK',
      isOpen: false,
      isConfirmation: false,
      onConfirmed: onConfirmed
    }))
  }

  const handleClose = (event, reason) => {
    var onClosed = () => {}
    if (typeof(alertDialog?.onClosed()) === 'function') {
      onClosed = alertDialog?.onClosed()
    }

    setAlertDialog(alertDialog => ({
      ...alertDialog,
      title: 'Error',
      closeButtonTitle: 'OK',
      isOpen: false,
      isConfirmation: false,
      onClosed: onClosed
    }))
  }

  return (
    <div>
      <Dialog
        open={open ?? alertDialog.isOpen}
        onClose={onClosed ?? handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={
          {
            style: {
              backgroundColor: '#282828',
              color: 'white'
            }
          }
        }
      >
        <DialogTitle id="alert-dialog-title">
          {title ?? alertDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'white', whiteSpace: 'pre-line' }} id="alert-dialog-description">
            {message ?? alertDialog.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'white' }} onClick={(isConfirmation ?? (alertDialog.isConfirmation ?? false)) ? handleConfirm : (onClosed ?? handleClose)}>{alertDialog.closeButtonTitle}</Button>
          {(isConfirmation ?? (alertDialog.isConfirmation ?? false)) ? (<Button style={{ color: 'white' }} onClick={(event, reason) => {
            setAlertDialog(alertDialog => ({
              ...alertDialog,
              isOpen: false,
            }))
          }}>{'Cancel'}</Button>) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog


