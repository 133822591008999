import { AppBar, Dialog, IconButton, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../../App"
import FeathersClient from '../../../feathers/feathersClient'
import { Add, Close, Delete, Edit } from "@mui/icons-material"
import BaseButton from "../../../components/button/button"
import Constants from '../../../constants'
import { StyledTableCell, StyledTableRow } from "../../../components/dataGrid/tableGrid"
import AddCustomerBankAccountFormPopup from "./addCustomerBankAccountFormPopup"
import useSnackbar from "../../../components/snackBar/useSnackbar"
import Utils from "../../../utils/utils"
import DetailRow from "../../../components/detailRow/detailRow"

export default function CustomerBankAccount({ user }) {
    const snackBar = useSnackbar()

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    //States
    const [editAccount, setEditAccount] = useState(null)
    const [list, setList] = useState([])
    const [result, setResult] = useState(null)

    //Ref
    const listRef = useRef(list)

    const countryCode = userInfo?.company?.countryCode

    //DATA GRID METHODS
    const getRows = () => {
        return list?.map((row, index) => {
            console.log(row)
            let withdrawalBank = result?.withdrawalBanks?.find((e) => e?.key === row?.withdrawalBank)?.value ?? '-'
            let withdrawalBankAccountNumber = row?.withdrawalBankAccountNumber ?? ''
            let bsbNumber = row?.bsbNumber ?? '-'
            let createdBy = row?.createdBy?.fullName ?? row?.createdBy?.userId ?? row?.createdBy?._id ?? '-'
            let createdAt = row?.createdAt ? Utils.shared.convertDateToString(Date.parse(row?.createdAt), Constants.date_format_2) : ''
            let updatedAt = row?.updatedAt ? Utils.shared.convertDateToString(Date.parse(row?.updatedAt), Constants.date_format_2) : ''

            return (
                <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{withdrawalBank}</StyledTableCell>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{withdrawalBankAccountNumber}</StyledTableCell>
                    {
                        (Utils.shared.supportsBSBNumber(userInfo)) ? (
                            <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{bsbNumber}</StyledTableCell>
                        ) : null
                    }
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>
                        <DetailRow title={'Created By:'} value={createdBy} />
                        <DetailRow title={'Created Date:'} value={createdAt} />
                        <DetailRow title={'Updated Date:'} value={updatedAt} />
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                        <Stack direction='row' spacing='5px' sx={{ alignItems: 'center', justifyContent: 'end' }}>
                            <Tooltip title='Edit Bank'>
                                <IconButton onClick={() => {
                                    setEditAccount(row)
                                }}>
                                    <Edit fontSize="small" htmlColor={Utils.shared.getColorByMode({lightColor: null, darkColor: 'white'})} />
                                </IconButton>
                            </Tooltip>
                            {
                                (listRef.current.length > 1) ? (
                                    <Tooltip title='Delete Account'>
                                        <IconButton onClick={() => {
                                            setAlertDialog(alertDialog => ({
                                                ...alertDialog,
                                                title: 'Delete Account',
                                                description: 'Are you sure?',
                                                isConfirmation: true,
                                                onConfirmed: () => {
                                                    FeathersClient.create('remove-player-withdrawal-account', {
                                                        'company': userInfo?.company?._id,
                                                        'user': user?._id,
                                                        'playerWithdrawalAccount': row?._id,
                                                    }, null, setAlertDialog, () => {
                                                        snackBar.showMessage('Account deleted successfully')
                                                    })
                                                },
                                                isOpen: true
                                            }))
                                        }}>
                                            <Delete fontSize="small" htmlColor={Utils.shared.getColorByMode({lightColor: null, darkColor: 'white'})} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null
                            }
                        </Stack>
                    </StyledTableCell>
                </StyledTableRow>
            )
        }) ?? []
    }

    //API METHODS
    const getList = () => {
        FeathersClient.create('get-player-withdrawal-accounts', {
            'company': userInfo?.company?._id,
            'user': user._id,
        }, null, setAlertDialog, (response) => {
            setResult(response)

            let playerWithdrawalAccounts = response?.playerWithdrawalAccounts ?? []
            setList([...[], ...playerWithdrawalAccounts])
            listRef.current = [...[], ...playerWithdrawalAccounts]
        })
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('player-withdrawal-accounts', 'created', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === user?.company?._id) &&
                data?.user?._id === user?._id) {
                setList([...listRef.current, data])
                listRef.current = [...listRef.current, data]
            }
        })

        FeathersClient.listen('player-withdrawal-accounts', 'patched', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                let tempList = [...listRef.current]
                tempList[index] = data
                listRef.current = tempList
                setList(tempList)
            }
        })

        FeathersClient.listen('player-withdrawal-accounts', 'removed', 'customer', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)
            setList(tempList)
            listRef.current = tempList
        }
    }

    useEffect(() => {
        if (user) {
            getList(0, true)
            startSocket()
        }
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px', alignItems: 'right' }} >
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Bank Name</StyledTableCell>
                                        <StyledTableCell>Bank Account Number</StyledTableCell>
                                        {
                                            (Utils.shared.supportsBSBNumber(userInfo)) ? (
                                                <StyledTableCell>BSB Number</StyledTableCell>
                                            ) : null
                                        }
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'>
                                            <BaseButton title='NEW' fullWidth={false} icon={<Add htmlColor={'#ffffff'} />} onTap={() => {
                                                setEditAccount({
                                                    fullName: '',
                                                    withdrawalBank: '',
                                                    withdrawalBankAccountNumber: '',
                                                })
                                            }} />
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                        <StyledTableCell component="th" scope="row" align="center" colSpan={(Utils.shared.supportsBSBNumber(userInfo)) ? 5 : 4}>
                                            No Record Found
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Stack>

                {/* Add Bank Account */}
                <Dialog open={editAccount} onClose={() => {
                    setEditAccount(null)
                }} PaperProps={{
                    sx: {
                        backgroundColor: Utils.shared.getColorByMode({lightColor: null, darkColor: '#505050'})
                    }
                }}>
                    <AppBar sx={{ position: 'sticky', backgroundColor: Constants.darkBackgroundColor }}>
                        <Toolbar variant="dense" disableGutters={true}>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h7" component="div">
                                {editAccount?._id ? 'EDIT ACCOUNT' : 'NEW ACCOUNT'}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setEditAccount(null)
                                }}
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <AddCustomerBankAccountFormPopup account={editAccount} user={user} result={result} onClosed={() => {
                        setEditAccount(null)
                    }} />
                </Dialog>
            </div>
        )
    } else {
        return null
    }
}