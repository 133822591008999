import { Button } from "@mui/material"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import { AlertDialogContext } from "../../App"
import { useContext, useEffect, useRef } from "react"

export default function FileUploadButton({ icon, accept = 'image/*', id, enableCompression = false, showAsBaseButton = false, onFileSelected }) {
    //Context
    const { setAlertDialog } = useContext(AlertDialogContext)

    return (
        <div>
            <input type="file"
                id={id}
                style={{ display: 'none' }}
                accept={accept}
                onInput={(e) => {
                    e.stopPropagation()

                    if (e.target.files.length > 0) {
                        if (enableCompression) {
                            let imageFile = e.target.files[0]
                            Utils.shared.getCompressedFile(imageFile, setAlertDialog, (result, file) => {
                                onFileSelected(result, file.name)
                            })
                        } else {
                            let file = e.target.files[0]
                            if (file.size > Constants.file_upload_size_limit) {
                                setAlertDialog(alertDialog => ({
                                    ...alertDialog,
                                    description: 'File size should not exceed 2MB',
                                    isOpen: true,
                                }))

                                e.target.value = null
                            } else {
                                Utils.shared.getBase64FromFile(file).then((result) => {
                                    onFileSelected(result, file.name)
                                }).catch((error) => {
                                    setAlertDialog(alertDialog => ({
                                        ...alertDialog,
                                        description: error.message,
                                        isOpen: true,
                                    }))
                                })
                            }
                        }
                    }
                }}
            />
            <label htmlFor={id}>
                {
                    showAsBaseButton ? (
                        <Button sx={{
                            svg: { color: 'gray' },
                            backgroundColor: '#68bc7a',
                            fontSize: '12px',
                            ':hover': {
                                backgroundColor: Utils.shared.brighten('#68bc7a', 1.1),
                            },
                        }} variant='contained' size='small' component="span">
                            {icon}
                        </Button>
                    ) : (
                        <Button sx={{ svg: { color: 'gray' }, textTransform: 'none' }} component="span">
                            {icon}
                        </Button>
                    )
                }
            </label>
        </div>
    )
}