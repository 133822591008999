import { Modal, Box, Backdrop, Zoom } from '@mui/material'
import { useContext } from 'react'
import { PopupContext } from '../../App'
import Constants from '../../constants'
import BaseButton from '../../components/button/button'
import useScreenType from 'react-screentype-hook'

function BasePopup({ children = null, isOpen = null, onClosed = null, fullWidth = false , closeOnBackdropTap = true }) {
    //Context
    const screenType = useScreenType()
    const { popup, setPopup } = useContext(PopupContext)

    const style = {
        maxWidth: fullWidth ? '100%' : screenType.isMobile ? '90%' : '40%',
        maxHeight: '90%',
        backgroundColor: 'white',
        color: 'white',
        border: `2px solid ${Constants.defaultPopupBorderColor}`,
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        overflow: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none'
        }
    }

    //Handlers
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            if (!closeOnBackdropTap) {
                return
            }
        }
        
        if (onClosed) {
            setPopup(popup => ({
                ...popup,
                children: null,
                onClosed: () => { }
            }))

            onClosed()
        } else {
            if (popup.onClosed()) {
                setPopup(popup => ({
                    ...popup,
                    isOpen: false,
                    children: null,
                    onClosed: popup.onClosed()
                }))
            } else {
                setPopup(popup => ({
                    ...popup,
                    isOpen: false,
                    children: null,
                    onClosed: () => { }
                }))
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen ?? popup.isOpen}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            closeAfterTransition
        >
            <Zoom in={isOpen ?? popup.isOpen}>
                <div style={{ maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', outline: 'none' }}>
                    <Box sx={style}>
                        {children ?? popup.children}
                        {(popup.showCloseButton ?? false) ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                                <BaseButton title={'OK'} fullWidth={false} onTap={handleClose} />
                            </div>
                        ) : null}
                    </Box>
                </div>
            </Zoom>
        </Modal>
    )
}

export default BasePopup