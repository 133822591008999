import { Stack, Switch, Box } from "@mui/material"
import Utils from "../../utils/utils"

export default function SwitchField({title, value = false, fontSize = '14px', textColor = Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }), textFullWidth = true, disabled = false, onChanged}) {
    return (
        <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Box sx={{ flexGrow: textFullWidth ? 1 : null }} style={{ color: textColor, fontSize: fontSize }}>{title}</Box>
            <Switch title={title} disabled={disabled} checked={value} onChange={(e, checked) => {
                onChanged(checked)
            }} />
        </Stack>
    )
}