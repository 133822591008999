import { AppBar, Box, Divider, IconButton, Stack, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { useGet } from 'figbird'
import Utils from "../../utils/utils";
import BaseButton from "../../components/button/button";
import { CustomerDetailsPopupContext, UserInfoContext } from "../../App";
import CustomerTransactionList from "./customerTransactionList";
import CustomerBetHistory from "./customerBetHistory";
import CustomerCredit from "./customerCredit";
import CustomerSettings from "./customerSettings";
import CustomerCase from "./customerCase";
import CustomerGame from "./customerGame";
import CustomerGameLog from "./customerGameLog";
import CustomerBankAccount from "./bankAccount/customerBankAccount";
import CustomerBalanceLog from "./customerBalanceLog";
import { Close } from "@mui/icons-material";

export default function CustomerDetails({ userId = null }) {
    const navigate = useNavigate()
    const search = useLocation().search
    const playerId = new URLSearchParams(search).get('id') ?? userId
    const { customerDetailsPopup, setCustomerDetailsPopup } = useContext(CustomerDetailsPopupContext)

    //Tabs
    const tabs = [
        {
            title: 'TRANSACTIONS',
        },
        {
            title: 'BET HISTORY',
        },
        {
            title: 'CREDIT',
        },
        {
            title: 'SETTINGS',
        },
        {
            title: 'CASE',
        },
        {
            title: 'GAME',
        },
        {
            title: 'GAME LOG',
        },
        {
            title: 'BALANCE LOG',
        },
        {
            title: 'BANK ACCOUNT',
        }
    ]

    //Context
    const { data } = useGet('users', playerId)
    const { userInfo } = useContext(UserInfoContext)

    //States
    const [user, setUser] = useState(data)
    const [tabIndex, setTabIndex] = useState(0)

    //Component Methods
    function GetDetailsRow({ title, value }) {
        return (
            <Stack direction='column' sx={{ padding: '10px' }}>
                <div style={{ fontSize: '12px', color: Utils.shared.getColorByMode({lightColor: null, darkColor: 'white'}) }}>{`${title}:`}</div>
                <div style={{ fontSize: '14px', whiteSpace: 'nowrap', color: Utils.shared.getColorByMode({lightColor: Constants.textRedColor, darkColor: 'white'}) }}><b>{value}</b></div>
            </Stack>
        )
    }
    const getTabContent = () => {
        if (user) {
            switch (tabIndex) {
                case 0:
                    return (
                        <CustomerTransactionList user={user} />
                    )
                case 1:
                    return (
                        <CustomerBetHistory user={user} />
                    )
                case 2:
                    return (
                        <CustomerCredit user={user} />
                    )
                case 3:
                    return (
                        <CustomerSettings user={user} />
                    )
                case 4:
                    return (
                        <CustomerCase user={user} />
                    )
                case 5:
                    return (
                        <CustomerGame user={user} />
                    )
                case 6:
                    return (
                        <CustomerGameLog user={user} />
                    )
                case 7:
                    return (
                        <CustomerBalanceLog user={user} />
                    )
                case 8:
                    return (
                        <CustomerBankAccount user={user} />
                    )
                default:
                    return null
            }
        } else {
            return null
        }
    }

    const getName = () => {
        let name = user?.fullName ?? "-"
        let upperLineName = user?.affiliateUser?.fullName ?? ''
        if (upperLineName.length > 0) {
            return `${name}(${upperLineName})`
        } else {
            return name
        }
    }

    //USE EFFECT
    useEffect(() => {
        if (!playerId) {
            //No player id, back to customer list
            navigate('/customers')
        }
    }, [])
    useEffect(() => {
        setUser(data)
    }, [data])

    const viewMobileNumber = userInfo?.viewMobileNumber ?? true
    const contactNumber = user?.contactNumber
    const selectedColor = Utils.shared.getColorByMode({lightColor: Constants.textPurpleColor, darkColor: 'rgba(255, 255, 255, 1.0)'})
    const unSelectedColor = Utils.shared.getColorByMode({lightColor: Constants.textBlueColor, darkColor: 'rgba(255, 255, 255, 0.5)'})
    const borderColor = Utils.shared.getColorByMode({lightColor: 'rgba(0,0,0,0.2)', darkColor: 'rgba(255, 255, 255, 0.3)'})

    return (
        <>
            <AppBar sx={{ position: 'sticky', backgroundColor: Constants.darkBackgroundColor }}>
                <Toolbar variant="dense" disableGutters={true}>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {`${customerDetailsPopup?.fullName ?? customerDetailsPopup?.userId} (${viewMobileNumber ? (customerDetailsPopup?.contactNumber ?? '-') : Utils.shared.getMaskedPhoneNumber(customerDetailsPopup?.contactNumber ?? '')})`}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            setCustomerDetailsPopup(null)
                        }}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ height: '100%', width: '100%' }}>
                <Stack direction='column' sx={{
                    backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#505050' })
                }}>
                    <Stack direction='row' spacing='0px' sx={{ overflowX: 'scroll', alignItems: 'center', maxHeight: '56px' }}>
                        <GetDetailsRow title='Name' value={getName()} sx={{ backgroundColor: borderColor }} />
                        <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: borderColor }} />
                        <GetDetailsRow title='ID' value={user?.userId ?? '-'} />
                        <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: borderColor }} />
                        <GetDetailsRow title='Affiliate' value={user?.referralCode ?? '-'} />
                        <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: borderColor }} />
                        <GetDetailsRow title='Mobile Number' value={viewMobileNumber ? (contactNumber ?? '-') : Utils.shared.getMaskedPhoneNumber(contactNumber ?? '')} />
                        <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: borderColor }} />
                        <GetDetailsRow title='Last Login' value={user?.lastLogin ? Utils.shared.convertDateToString(Date.parse(user?.lastLogin), Constants.date_format_2) : '-'} />
                        <Stack direction='row' spacing='15px' sx={{ padding: '10px' }}>
                            <BaseButton title='WALLET' onTap={() => {
                                let link = `https://${user?.company?.domains[0]}/adminlogin/${user?.playerUniqueIdAutoLink}`
                                window.open(link, '_blank')?.focus()
                            }} />
                            <BaseButton title='CHAT' onTap={() => {
                                if (user?.rooms) {
                                    let roomId = user?.rooms[0]?._id

                                    FeathersClient.get('rooms', roomId, {
                                        query: {
                                            '$populate': [
                                                'user',
                                                'company',
                                                'lastMessage',
                                            ]
                                        }
                                    }, null, (response) => {
                                        navigate('/liveChat', {
                                            state: {
                                                preSelectedRoom: response
                                            }
                                        })
                                    })
                                }
                            }} />
                            <BaseButton title={'DOWNLINE'} onTap={() => {
                                let host = window.location.host
                                window.open(`https://${host}/customers?upline=${user?.userId}`, '_self')
                            }} />
                        </Stack>
                    </Stack>
                    <Divider sx={{ backgroundColor: borderColor }} />
                    <Box sx={{ borderBottom: 1, borderColor: borderColor, color: Utils.shared.getColorByMode({lightColor: Constants.textBlueColor, darkColor: 'rgba(255, 255, 255, 0.5)'}) }}>
                        <Tabs variant='scrollable' value={tabIndex} textColor='inherit' TabIndicatorProps={{
                            style: {
                                background: selectedColor,
                                height: 4
                            }
                        }} sx={{
                            color: Utils.shared.getColorByMode({lightColor: Constants.textBlueColor, darkColor: 'rgba(255, 255, 255, 0.5)'}),
                        }} onChange={(event, index) => {
                            setTabIndex(index)
                        }}>
                            {tabs.map((e, index) => {
                                let isSelected = index === tabIndex
                                
                                return <Tab key={`tabItem${index}`} label={<b>{e.title}</b>} wrapped={false} sx={{ color: isSelected ? selectedColor : unSelectedColor, fontSize: '12px' }} />
                            })}
                        </Tabs>
                    </Box>
                    <div style={{ width: '100%', hright: '100%' }}>
                        {getTabContent()}
                    </div>
                </Stack>
            </div>
        </>

    )
}