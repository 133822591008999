import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import PageNotFound from './pages/pageNotFound';
import { CircularProgress } from '@mui/material';
import Constants from './constants'
import GameCategorySettings from './pages/customize/gameSettings/gameCategorySettings';
import Utils from './utils/utils';

//Auth
const Login = lazy(() => import('./pages/auth/login'));
const ChangePassword = lazy(() => import('./pages/profile/changePassword'));

//Details
const LiveChat = lazy(() => import('./pages/liveChat/liveChat'));
const CustomerDetails = lazy(() => import('./pages/customerList/customerDetails'));

//List
const BankTransaction = lazy(() => import('./pages/bankTransaction/bankTransaction'));
const CustomerList = lazy(() => import('./pages/customerList/customerList'));
const BonusList = lazy(() => import('./pages/promotion/bonusList'));
const CommissionList = lazy(() => import('./pages/commission/commissionList'));
const CommissionPayoutList = lazy(() => import('./pages/commission/commissionPayoutList'));
const RebateList = lazy(() => import('./pages/rebate/rebateList'));
const RebatePayoutList = lazy(() => import('./pages/rebate/rebatePayoutList'));

//Settings
const LanguageSettings = lazy(() => import('./pages/settings/languageSettings/languageSettings'));
const BotSettings = lazy(() => import('./pages/settings/botSettings/botSettings'));
const ChatTemplatesSettings = lazy(() => import('./pages/settings/chatTemplatesSettings'));
const CommissionSettings = lazy(() => import('./pages/promotion/commissionSettings'));
const BankSettings = lazy(() => import('./pages/settings/bankSettings/bankSettings'));
const ContactSettings = lazy(() => import('./pages/settings/contactSettings/contactSettings'));
const MemberSettings = lazy(() => import('./pages/settings/memberSettings'));
const PaymentGatewaySettings = lazy(() => import('./pages/settings/paymentGatewaySettings/paymentGatewaySettings'));
const TransactionSettings = lazy(() => import('./pages/settings/transactionSettings'));
const PhoneCodeSettings = lazy(() => import('./pages/settings/phoneCodeSettings'));
const DomainSettings = lazy(() => import('./pages/settings/domainSettings'));
const SMSSettings = lazy(() => import('./pages/settings/sms/smsSettings'));
const UserRoleSettings = lazy(() => import('./pages/settings/userRoleSettings/userRoleSettings'));
const WhatsappBotSettings = lazy(() => import('./pages/settings/botSettings/whatsAppBotSettings/whatsappBotSettings'));
const ThemeSettings = lazy(() => import('./pages/customize/themeSettings/themeSettings'));
const DisplaySettings = lazy(() => import('./pages/customize/displaySettings/displaySettings'));
const BannerSettings = lazy(() => import('./pages/customize/bannerSettings/bannerSettings'));
const GameSettings = lazy(() => import('./pages/customize/gameSettings/gameSettings'));
const BonusSettings = lazy(() => import('./pages/promotion/bonusSettings/bonusSettings'));
const RebateSettings = lazy(() => import('./pages/promotion/rebateSettings'));

//History
const BankTransactionHistory = lazy(() => import('./pages/settings/bankSettings/bankTransactionHistory'));
const SMSHistory = lazy(() => import('./pages/settings/sms/smsHistory'));

//Reports
const ReportExport = lazy(() => import('./pages/export/reportExport'));
const BankReport = lazy(() => import('./pages/reports/bankReport'));
const BonusReport = lazy(() => import('./pages/reports/bonusReport'));
const RebateReport = lazy(() => import('./pages/reports/rebateReport'));
const CommissionReport = lazy(() => import('./pages/reports/commissionReport'));
const ManualForfeitReport = lazy(() => import('./pages/reports/manualForfeitReport'));
const StaffReport = lazy(() => import('./pages/reports/staffReport'));
const DepositWithdrawReport = lazy(() => import('./pages/reports/depositWithdrawReport'));
const GameReport = lazy(() => import('./pages/reports/gameReport'));
const CustomerReport = lazy(() => import('./pages/reports/customerReport'));
const WinLossReport = lazy(() => import('./pages/reports/winLossReport'));

const container = document.getElementById('root');
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <Suspense fallback={
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', color: Constants.defaultDrawerHeaderColor, backgroundColor: Utils.shared.getColorByMode({lightColor: 'white', darkColor: 'black'}) }}>
        <CircularProgress color='inherit' size={50} thickness={6} style={{ backgroundColor: 'transparent' }} />
      </div>
    }>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="login" element={<Login />} />
          <Route path="liveChat" element={<LiveChat />} />
          <Route path="bankTransaction" element={<BankTransaction />} />

          <Route path="customers" element={<CustomerList />} />
          <Route path="customers/details" element={<CustomerDetails />} />

          <Route path="settings/transaction" element={<TransactionSettings />} />
          <Route path="settings/bank" element={<BankSettings />} />
          <Route path="settings/bank/history" element={<BankTransactionHistory />} />
          <Route path="settings/paymentGateway" element={<PaymentGatewaySettings />} />
          <Route path="settings/contact" element={<ContactSettings />} />
          <Route path="settings/member" element={<MemberSettings />} />
          <Route path="settings/phoneCode" element={<PhoneCodeSettings />} />
          <Route path="settings/domain" element={<DomainSettings />} />
          <Route path="settings/sms" element={<SMSSettings />} />
          <Route path="settings/sms/history" element={<SMSHistory />} />
          <Route path="settings/user" element={<UserRoleSettings />} />
          <Route path="settings/language" element={<LanguageSettings />} />
          <Route path="settings/whatsapp" element={<WhatsappBotSettings />} />
          <Route path="settings/bots" element={<BotSettings />} />
          <Route path="settings/rebate" element={<RebateSettings />} />
          <Route path="settings/commission" element={<CommissionSettings />} />
          <Route path="settings/chatTemplates" element={<ChatTemplatesSettings />} />

          <Route path="customize/theme" element={<ThemeSettings />} />
          <Route path="customize/display" element={<DisplaySettings />} />
          <Route path="customize/banner" element={<BannerSettings />} />
          <Route path="customize/gameCategory" element={<GameCategorySettings />} />
          <Route path="customize/game" element={<GameSettings />} />

          <Route path="bonus" element={<BonusSettings />} />
          <Route path="bonus/list" element={<BonusList />} />

          <Route path="commission" element={<CommissionList />} />
          <Route path="commission/details" element={<CommissionPayoutList />} />

          <Route path="rebate" element={<RebateList />} />
          <Route path="rebate/details" element={<RebatePayoutList />} />

          <Route path="export" element={<ReportExport />} />

          <Route path="profile/changePassword" element={<ChangePassword />} />

          <Route path="report/bank" element={<BankReport />} />
          <Route path="report/bonus" element={<BonusReport />} />
          <Route path="report/rebate" element={<RebateReport />} />
          <Route path="report/commission" element={<CommissionReport />} />
          <Route path="report/manualForfeit" element={<ManualForfeitReport />} />
          <Route path="report/staff" element={<StaffReport />} />
          <Route path="report/depositWithdraw" element={<DepositWithdrawReport />} />
          <Route path="report/game" element={<GameReport />} />
          <Route path="report/customer" element={<CustomerReport />} />
          <Route path="report/winLoss" element={<WinLossReport />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
