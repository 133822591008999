import { AppBar, Dialog, Grid, IconButton, Paper, Stack, Switch, Table, TableBody, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material"
import useSnackbar from "../../../components/snackBar/useSnackbar"
import { AlertDialogContext, ListingContext, UserInfoContext } from "../../../App"
import { useContext, useEffect, useRef, useState } from "react"
import FeathersClient from '../../../feathers/feathersClient'
import BaseButton from "../../../components/button/button"
import { StyledTableCell, StyledTableRow } from "../../../components/dataGrid/tableGrid"
import Constants from '../../../constants'
import { Add, Cancel, Close, Delete, Edit, Reorder } from "@mui/icons-material"
import EditSequenceField from "../../../components/textfield/editSequenceField"
import Utils from "../../../utils/utils"
import FileUploadButton from "../../../components/fileUploadField/fileUploadButton"
import AddEditCustomCategory from "./addEditCustomCategoryPopup"

export default function GameCategorySettings() {
    //Pagination Constants
    const rowPerPage = 20

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const snackBar = useSnackbar()
    const { listing } = useContext(ListingContext)

    //States
    const [values, setValues] = useState({
        case: '',
        caseError: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])
    const [editList, setEditList] = useState([])
    const [result, setResult] = useState(null)
    const [isReorder, setIsReorder] = useState(false)
    const [editCategory, setEditCategory] = useState(null)

    //Ref
    const listRef = useRef(list)
    const editListRef = useRef(editList)
    const valuesRef = useRef(values)

    //DATA GRID METHODS
    const getRows = () => {
        return list?.map((e, index) => {
            let no = index + 1
            const id = e?._id
            let sequence = e?.sequence
            let name = e?.name ?? '-'
            let active = e?.active ?? false

            let image = e?.image ?? ''
            let link = image.length > 0 ? Utils.shared.getCompanyCustomGamesImagesLink(listing, image) : `${process.env.PUBLIC_URL}/images/icons/others.png`

            const companyGames = e?.companyGames?.map((companyGame) => {
                console.log(companyGame)
                return result?.companyGames?.find((i) => i.id === companyGame?.companyGame)
            })

            return (
                <>
                    <StyledTableRow key={`row${index}`} hover={true}>
                        <StyledTableCell>
                            {isReorder ? <EditSequenceField key={`editSequenceField${index}`} index={index} originSequence={sequence} onSequenceChanged={(value) => {
                                editListRef.current = editListRef.current.map((e, mapIndex) => {
                                    if (mapIndex === index) {
                                        e.sequence = value
                                    }

                                    return e
                                })
                            }} /> : no}
                        </StyledTableCell>
                        <StyledTableCell>
                            <Stack direction='row' spacing='5px' sx={{ alignItems: 'center' }}>
                                <img src={link} height='80px' width='80px' alt='gameLogo' />
                                <Stack>
                                    <Tooltip title='Edit game logo'>
                                        <FileUploadButton key={`fileUploadButton${index}`} icon={<Edit fontSize='small' />} id={`uploadGameLogo${index}`} onFileSelected={(result) => {
                                            patchCompanyCustomGame(id, {
                                                image: result
                                            }, (response) => {
                                                snackBar.showMessage('Game category icon updated successfully')
                                            })
                                        }} />
                                    </Tooltip>
                                    {image.length > 0 ? (
                                        <Tooltip title='Revert back to original game logo'>
                                            <IconButton sx={{ svg: { color: Constants.buttonRedColor } }} onClick={() => {
                                                setAlertDialog(alertDialog => ({
                                                    ...alertDialog,
                                                    title: 'Game Category Icon',
                                                    description: 'Revert back to original?',
                                                    isConfirmation: true,
                                                    onConfirmed: () => {
                                                        patchCompanyCustomGame(id, {
                                                            image: null
                                                        }, (response) => {
                                                            snackBar.showMessage('Game category icon reverted')
                                                        })
                                                    },
                                                    isOpen: true
                                                }))
                                            }}>
                                                <Cancel fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                </Stack>
                            </Stack>
                        </StyledTableCell>
                        <StyledTableCell>{name}</StyledTableCell>
                        <StyledTableCell>
                            <Stack spacing={'5px'}>
                                {
                                    e?.companyGames?.map((companyGame) => {
                                        let companyGameObject = result?.companyGames?.find((i) => i.id === companyGame?.companyGame)
                                        let companyGameCategory = result?.gameCategories?.find((i) => i.key === companyGame?.companyGameCategory)
                                        let gameImageLink = Utils.shared.getGamesImagesLink(listing, companyGameObject?.game?.image)
                                        return (
                                            <Stack direction={'row'} spacing='2px' alignItems={'center'}>
                                                <img src={gameImageLink} height='24px' width='24px' />
                                                <Typography sx={{ fontSize: '12px' }}>
                                                    {companyGameObject?.game?.label ?? '-'}
                                                </Typography>
                                                <Typography sx={{ fontSize: '10px' }}>
                                                    {`(${companyGameCategory?.value ?? ''})`}
                                                </Typography>
                                            </Stack>
                                        )
                                    })
                                }
                            </Stack>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Switch checked={active} onChange={(e, checked) => {
                                setAlertDialog(alertDialog => ({
                                    ...alertDialog,
                                    title: `${checked ? 'Activate' : 'Deactivate'} (${name})`,
                                    description: 'Are you sure?',
                                    isConfirmation: true,
                                    onConfirmed: () => {
                                        patchCompanyCustomGame(id, {
                                            active: checked
                                        }, (response) => {
                                            snackBar.showMessage('Saved successfully')
                                        })
                                    },
                                    isOpen: true
                                }))
                            }} />
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                            <Stack direction='row' sx={{ justifyContent: 'end' }}>
                                <Tooltip title='Edit'>
                                    <IconButton onClick={() => {
                                        setEditCategory(e)
                                    }}>
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <IconButton onClick={() => {
                                        setAlertDialog(alertDialog => ({
                                            ...alertDialog,
                                            title: `Delete ${name ?? '-'}`,
                                            description: 'Are you sure?',
                                            isConfirmation: true,
                                            onConfirmed: () => {
                                                FeathersClient.remove('company-custom-games', e._id, null, setAlertDialog, (response) => {
                                                    snackBar.showMessage('Category deleted successfully')

                                                })
                                            },
                                            isOpen: true
                                        }))
                                    }}>
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </StyledTableCell>
                    </StyledTableRow>
                </>
            )
        }) ?? []
    }

    //API METHODS
    const getCompanyCustomGameList = () => {
        FeathersClient.create('get-company-custom-games', {
            company: userInfo?.company?._id,
        }, null, setAlertDialog, (response) => {
            let data = response?.companyCustomGames ?? []
            data?.sort((a, b) => a?.sequence - b?.sequence)
            setList(data)
            setEditList(data)
            setResult(response)
        })
    }
    const saveCompanyCustomGameSequenceList = () => {
        editListRef.current?.sort((a, b) => a?.sequence - b?.sequence)

        let data = {
            sequences: editListRef.current?.map((e) => {
                return {
                    'companyCustomGames': e?._id,
                }
            })
        }

        FeathersClient.create('company-custom-games-arrangement', data, null, setAlertDialog, (response) => {
            setIsReorder(false)
            getCompanyCustomGameList()
            snackBar.showMessage('Arrangement saved successfully')
        })
    }
    const patchCompanyCustomGame = (id, data, onPatched, completion = null) => {
        FeathersClient.patch('company-custom-games', id, data, {
            query: {
                '$populate': [
                    'company',
                ]
            }
        }, setAlertDialog, (response) => {
            onPatched(response)
        }, null, () => {
            if (completion) {
                completion()
            }
        })
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('company-custom-games', 'created', 'settings', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === userInfo?.company?._id)) {

                let tempList = [data, ...listRef.current]
                let sortedList = tempList.sort((a, b) => a?.sequence - b?.sequence)

                setList(sortedList)
                setEditList(sortedList)
            }
        })

        FeathersClient.listen('company-custom-games', 'patched', 'settings', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                let tempList = [...listRef.current]
                tempList[index] = data
                let sortedList = tempList.sort((a, b) => a?.sequence - b?.sequence)
                listRef.current = sortedList
                editListRef.current = sortedList
                setList(sortedList)
                setEditList(sortedList)
            }
        })

        FeathersClient.listen('company-custom-games', 'removed', 'settings', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)

            let sortedList = tempList.sort((a, b) => a?.sequence - b?.sequence)
            setList(sortedList)
            setEditList(sortedList)
        }
    }

    //Use Effects
    useEffect(() => {
        getCompanyCustomGameList()
        startSocket()
    }, [])
    useEffect(() => {
        listRef.current = list
    }, [list])
    useEffect(() => {
        editListRef.current = editList
    }, [editList])

    return (
        <>
            <Stack direction='column' spacing='15px' sx={{ padding: '15px 10px 10px 10px', minHeight: '100%', backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#505050' }) }} >
                <Stack direction='row-reverse' spacing='10px' sx={{ alignItems: 'center' }}>
                    <Stack direction='row-reverse'>
                        {isReorder ? (
                            <Stack direction='row' spacing='10px'>
                                <BaseButton title='SAVE ARRANGEMENT' fullWidth={false} onTap={() => {
                                    saveCompanyCustomGameSequenceList()
                                }} />
                                <BaseButton title='CANCEL' color={Constants.defaultCancelButtonColor} fullWidth={false} onTap={() => {
                                    editListRef.current = editList
                                    setIsReorder(false)
                                }} />
                            </Stack>
                        ) : (
                            <BaseButton title={'EDIT ARRANGEMENT'} fullWidth={false} icon={<Reorder htmlColor={'#ffffff'} />} color='#2f3539' onTap={() => {
                                setIsReorder(true)
                            }} />
                        )}
                    </Stack>
                    <BaseButton title='NEW CATEGORY' fullWidth={false} icon={<Add />} onTap={() => {
                        setEditCategory({
                            name: '',
                            companyGames: []
                        })
                    }} />
                </Stack>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>No</StyledTableCell>
                                    <StyledTableCell>Icon</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Games</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell align='right'>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                    <StyledTableCell component="th" scope="row" align="center" colSpan={6}>
                                        No Record Found
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Stack>

            {/* Edit Rate Popup */}
            <Dialog open={editCategory != null} onClose={() => {
                setEditCategory(null)
            }}>
                <AppBar sx={{ position: 'sticky', backgroundColor: Constants.darkBackgroundColor }}>
                    <Toolbar variant="dense" disableGutters={true}>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h7" component="div">
                            {editCategory?._id ? 'EDIT CATEGORY' : 'NEW CATEGORY'}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setEditCategory(null)
                            }}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddEditCustomCategory gameCategory={editCategory} result={result} onClosed={() => {
                    setEditCategory(null)
                }} />
            </Dialog>
        </>
    )
}