// API
//Live - gcsys.co:3310
//Staging - gcsys.link:3310
const baseURL = 'https://gcsys.co:3310'
const timeoutSeconds = 60
const fileSizeLimit = 2000000
const feathersJWTKey = 'FEATHERSJS_ACCESS_TOKEN_BACK_OFFICE'

const api_authentication = "/authentication"
const api_companies = "/companies"
const api_games = "/games"
const api_upload_games_image = "/upload-games-image"
const api_upload_companies_image = "/upload-companies-image"
const api_upload_company_games_image = "/upload-company-games-image"
const api_upload_company_banks_image = "/upload-company-banks-image"
const api_upload_company_games_sequence_image = "/upload-company-games-sequence-image"
const api_upload_messages_image = "/upload-messages-image"
const api_upload_transactions_attachment = "/upload-transactions-attachment"
const api_upload_whatsapp_bot_qr_image = "/upload-whatsapp-bot-qr-image"
const api_upload_company_contacts_image = "/upload-company-contacts-image"
const api_upload_bonus_image = '/upload-bonus-image'

const bucket_path_messages = '/upload/messages/images/'
const bucket_path_bonus = '/upload/bonus/images/'
const bucket_path_games = '/upload/games/images/'
const bucket_path_companies = '/upload/companies/images/'
const bucket_path_company_games_sequence = '/upload/company-games-sequence/images/'
const bucket_path_company_custom_games = '/upload/company-custom-games/images/'
const bucket_path_whatsapp_bot_qr_image = '/upload/whatsapp-bot-qr/images/'
const bucket_path_reports = '/upload/reports/'

const file_upload_size_limit = 2000000

// Feathers Error Code
const feathers_error_code_claim_bonus_before_play = 451
const feathers_error_code_turnover_rollover_error = 453
const feathers_error_code_whatsapp_bot_qr_expired = 446
const feathers_error_code_room_not_found = 449

// Shared Preference Keys
const keys_app_version = "KEYS_APP_VERSION"
const keys_user_object = "KEYS_USER_OBJECT"
const keys_company_id = "KEYS_COMPANY_ID"
const keys_device_id = "KEYS_DEVICE_ID"
const keys_room_id = "KEYS_ROOM_ID"
const keys_welcome_notice = "KEYS_WELCOME_NOTICE_STOP_SHOWING_TODAY"
const keys_dark_mode = "KEYS_DARK_MODE"

const defaultMainColor = 'white'
const defaultTopBarColor = '#3AAFA9'
const defaultDrawerHeaderColor = '#474c50'
const darkBackgroundColor = '#2f3539'

const darkModeBackgroundColor = '#505050'
const darkModeTableRowColor = '#757575'
const darkModeTableFontColor = '#FFFFFF'
const darkModePaginationColor = '#2f3539'
const darkModePaginationFontColor = '#FFFFFF'

// Button Color
const defaultButtonColor = '#3AAFA9'
const defaultCancelButtonColor = '#BDBDBD'
const defaultButtonFontColor = '#ffffff'
const buttonRedColor = '#cc4336'
const buttonGreenColor = '#4ba614'
const buttonOrangeColor = '#cf8032'
const buttonIndigoColor = '#462bc2'

// Text Color
const textBlueColor = '#3F51B5'
const textRedColor = '#f54242'
const textGreenColor = '#388E3C'
const textOrangeColor = '#F57C00'
const textLightGreenColor = '#0097A7'
const textPurpleColor = '#9C27B0'

// Popup Color
const defaultPopupBorderColor = '#000000'

// Row Color
const rowRedColor = '#f54242'

// DATE FORMAT
const date_format_1 = 'dd-MM-yyyy'
const date_format_2 = 'dd-MM-yyyy hh:mmaa'

//Main Control role
const roleMCAdmin = 1;
const roleMCManager = 2;
const roleMCCS = 3;
//BO role
const roleBOAdmin = 4;
const roleBOStaff = 5;
const roleBOAgent = 6;
const roleBOCS = 7;
const roleBOKounter = 8;
const roleBOHelper = 9;
//Player role
const rolePlayer = 10;

// GAME BEHAVIOR
const gameBehaviourCustomLayout = 1
const gameBehaviourOpenLink = 2
const gameBehaviourDownloadAPP = 3
const gameBehaviourCustomLayoutFEHost = 4
const gameBehaviourOpenLinkFEHost = 5
const gameBehaviourCustomLayoutCallGameLink = 6

// PaymentGateway Methods
const paymentGatewayMethodOnlineBanking = 1
const paymentGatewayMethodQRPayment = 2
const paymentGatewayMethodTelco = 3
const paymentGatewayMethodUSDTEtherium = 4
const paymentGatewayMethodUSDTTron = 5

//Transaction status
const transactionStatusPending = 1
const transactionStatusProcessing = 2
const transactionStatusApproved = 3
const transactionStatusRejected = 4
const transactionStatusCancelled = 5

//Transaction type
const transactionTypeDeposit = 1;
const transactionTypeWithdrawal = 2; //Withdrawal display in negative
const transactionTypeBonus = 3;
const transactionTypeManual = 4;
const transactionTypeRebate = 5;
const transactionTypeForfeited = 6; //Forfeited display in negative
const transactionTypeCommission = 7;
const transactionTypeLossCredit = 8;
const transactionTypeBankTransfer = 9;
const transactionTypeDepositFee = 10; //Deposit Fee display in negative

//Commission Payout Status
const commissionPayoutStatusPending = 1
const commissionPayoutStatusPaid = 2
const commissionPayoutStatusClaim = 3

//Bonus Input type
const bonusInputTypePercentage = 1
const bonusInputTypeFixedAmount = 2
const bonusInputTypeRandom = 3

//Bonus Input Random type
const bonusInputRandomTypeRange = 1;
const bonusInputRandomTypeAmount = 2;

//Bonus Platform
const bonusPlatformWeb = 1;
const bonusPlatformApp = 2;
const bonusPlatformAll = 3; //default

//Bonus Angpow Display Type
const bonusImageTypeNormal = 1;
const bonusImageTypeLong = 2;

//Rebate Type
const rebateTypeWinLoss = 1;//Default
const rebateTypeTotalBet = 2;

//Setting Affiliate
const affiliateCommissionOptionManual = 1; //Default
const affiliateCommissionOptionFirstDepositOnly = 2;
const affiliateCommissionTypeWinLoss = 1;//Default
const affiliateCommissionTypeTotalBet = 2;

//Message Mode
const roomMessageModeLive = 1;
const roomMessageModeWhatsApp = 2;

//Messages indicator
const messagesIndicatorWA = 1;
const messagesIndicatorWASMS = 2;
const messagesIndicatorLIVE = 3;

//Contact Type
const contactTypeWhatsApp = 1
const contactTypeTelegram = 2
const contactTypeWechat = 3
const contactTypeCustom = 99

//User status
const userStatusActive = 1;
const userStatusEmergency = 2;
const userStatusSuspended = 3;
const userStatusInactive = 4;

//Transaction Remarks
const transactionRemarksFundNoReceived = 1;
const transactionRemarksOther = 2;

//chatCommand type
const chatCommandTypeNotification = 1;
const chatCommandTypeChat = 2;
//Whatsapp Referral
const chatCommandTypeWhatsappReferral = 3;
//Line Reply
const chatCommandTypeLineReply = 4;

//report status
const reportStatusInProgress = 1;
const reportStatusCompleted = 2;
const reportStatusFailed = 3;

const defaultCountryCode = 'MY'; //Malaysia
const countryCodeTH = 'TH'; //Thailand
const countryCodeVN = 'VN'; //Vietnam
const countryCodeSG = 'SG'; //Singapore
const countryCodeID = 'ID'; //Indonesia
const countryCodeUS = 'US'; //United States
const countryCodeKH = 'KH'; //Cambodia
const countryCodeBD = 'BD'; //Bangladesh
const countryCodeIN = 'IN'; //India
const countryCodeMM = 'MM'; //Myanmar
const countryCodeAU = 'AU'; //Australia
const countryCodeBN = 'BN'; //Brunei


//report data type
const reportDataTypeTransaction = 1;
const reportDataTypeRegistrationDate = 2;

//Game Code
//XE88
const gameCodeXE88 = 1;
//Mario Club
const gameCodeMarioClub = 2;
//Kiss918
const gameCodeKiss918 = 3;
//Pussy888
const gameCodePussy888 = 4;
//Mega888
const gameCodeMega888 = 5;
//Evo888
const gameCodeEvo888 = 6;
//BGGaming
const gameCodeBGGaming = 7;
//Playtech
const gameCodePlaytech = 8;
//VPower
const gameCodeVPower = 9;
//Joker
const gameCodeJoker = 10;
//IBC
const gameCodeIBC = 11;
//GreatWall
const gameCodeGreatWall = 12;
//Allbet
const gameCodeAllbet = 13;
//EG93 (ALBB)
const gameCodeALBBEG93 = 14;
//SV388 (AWC)
const gameCodeAWCSV388 = 15;
//SEXYBCRT (AWC)
const gameCodeAWCSEXYBCRT = 16;
//HORSEBOOK (AWC)
const gameCodeAWCHORSEBOOK = 17;
//M8Bet
const gameCodeM8Bet = 18;
//ASIAGAMING (ETG)
const gameCodeETGASIAGAMING = 19;
//CLUBSUNCITY (ETG)
const gameCodeETGCLUBSUNCITY = 20;
//ACE333 (ETG)
const gameCodeETGACE333 = 21;
//SBO (ETG)
const gameCodeETGSBO = 22;
//918KAYA (ETG)
const gameCodeETG918KAYA = 23;
//PLAYBOY (ETG)
const gameCodeETGPLAYBOY = 24;
//JDB (ETG)
const gameCodeETGJDB = 25;
//JILI (ETG)
const gameCodeETGJILI = 26;
//LIVE22 (ETG)
const gameCodeETGLIVE22 = 27;
//KING855 (ETG)
const gameCodeETGKING855 = 28;
//CQ9 (ETG)
const gameCodeETGCQ9 = 29;
//HABANERO (ETG)
const gameCodeETGHABANERO = 30;
//LIONKING (ETG)
const gameCodeETGLIONKING = 31;
//PLAYNGO (ETG)
const gameCodeETGPLAYNGO = 32;
//PRAGMATIC (ETG)
const gameCodeETGPRAGMATIC = 33;
//SPADEGAMING (ETG)
const gameCodeETGSPADEGAMING = 34;
//SAGAMING (ETG)
const gameCodeETGSAGAMING = 35;
//Ekor
const gameCodeEkor = 36;
//3WIN8 (ETG)
const gameCodeETG3WIN8 = 37;
//Funhouse
const gameCodeFunhouse = 38;
//FUNKY GAME (ETG)
const gameCodeETGFUNKYGAME = 39;
//DRAGOON SOFT (ETG)
const gameCodeETGDRAGOONSOFT = 40;
//GAMEPLAY (ETG)
const gameCodeETGGAMEPLAY = 41;
//Booongo
const gameCodeBooongo = 42;
//Ace333
const gameCodeAce333 = 43;
//King855
const gameCodeKing855 = 44;
//SBO
const gameCodeSBO = 45;
//Pragmatic (Slot)
const gameCodePragmaticSlot = 46;
//28Win (ALBB)
const gameCodeALBB28Win = 47;
//SAGaming
const gameCodeSAGaming = 48;
//28Win(2) (ALBB)
const gameCodeALBB28Win2 = 49;
//SBO(2)
const gameCodeSBO2 = 50;
//Coba8 (ALBB)
const gameCodeALBBCoba8 = 51;
//Pragmatic(S) (Slot)
const gameCodePragmaticSSlot = 52;
//JILI
const gameCodeJILI = 53;
//ClubSuncity
const gameCodeClubSuncity = 54;
//JILI (AWC)
const gameCodeAWCJILI = 55;
//JDB (AWC)
const gameCodeAWCJDB = 56;
//FC (AWC)
const gameCodeAWCFC = 57;
//AWS (AWC)
const gameCodeAWCAWS = 58;
//VENUS (AWC)
const gameCodeAWCVENUS = 59;
//JDBFISH (AWC)
const gameCodeAWCJDBFISH = 60;
//YL (AWC)
const gameCodeAWCYL = 61;
//Pragmatic (Live)
const gameCodePragmaticLive = 62;
//Pragmatic(S) (Live)
const gameCodePragmaticSLive = 63;
//Evolution
const gameCodeEvolution = 64;
//SpadeGaming
const gameCodeSpadeGaming = 65;
//SPADE (AWC)
const gameCodeAWCSPADE = 66;
//E1SPORT (AWC)
const gameCodeAWCE1SPORT = 67;
//YEEBET (Live)
const gameCodeYEEBET = 68;
//AGGaming (Live)
const gameCodeAGGaming = 69;
//3WIN8 (Slot)
const gameCode3WIN8 = 70;
//CQ9 (Slot)
const gameCodeCQ9 = 71;
//LIVE22 (Slot)
const gameCodeLIVE22 = 72;
//918KAYA (Slot)
const gameCode918KAYA = 73;
//PLAYBOY (Slot)
const gameCodePLAYBOY = 74;
//HABANERO (Slot)
const gameCodeHABANERO = 75;
//918KISS2 (Slot)
const gameCode918KISS2 = 76;
//XE88 (ETG)
const gameCodeETGXE88 = 77;
//MARIO CLUB (ETG)
const gameCodeETGMARIOCLUB = 78;
//JOKER (ETG)
const gameCodeETGJOKER = 79;
//M8 SPORT (ETG)
const gameCodeETGM8SPORT = 80;
//YL FISHING (ETG)
const gameCodeETGYLFISHING = 81;
//MEGAWIN (ETG)
const gameCodeETGMEGAWIN = 82;
//EVO888 (ETG)
const gameCodeETGEVO888 = 83;
//MEGA888 (ETG)
const gameCodeETGMEGA888 = 84;
//GREATWALL (ETG)
const gameCodeETGGREATWALL = 85;
//GRAND DRAGON LOTTO (ETG)
const gameCodeETGGRANDDRAGONLOTTO = 86;
//GRAND DRAGON MKT (ETG)
const gameCodeETGGRANDDRAGONMKT = 87;
//KINGMAKER (ETG)
const gameCodeETGKINGMAKER = 88;
//PLAYTECH (ETG)
const gameCodeETGPLAYTECH = 89;
//SV388 (ETG)
const gameCodeETGSV388 = 90;
//SEXY BACCARAT (ETG)
const gameCodeETGSEXYBACCARAT = 91;
//918KISS (ETG)
const gameCodeETG918KISS = 92;
//PUSSY888 (ETG)
const gameCodeETGPUSSY888 = 93;
//VPOWER (ETG)
const gameCodeETGVPOWER = 94;
//ALLBET (ETG)
const gameCodeETGALLBET = 95;
//918KISSH5 (ETG)
const gameCodeETG918KISSH5 = 96;
//BG (AWC)
const gameCodeAWCBG = 97;
//IBC (ETG)
const gameCodeETGIBC = 98;
//BG (ETG)
const gameCodeETGBG = 99;
//MPOKER (ETG)
const gameCodeETGMPOKER = 100;
//ASIAGAMINGS (ETG)
const gameCodeETGASIAGAMINGS = 101;
//OBET33 (ETG)
const gameCodeETGOBET33 = 102;
//MICROGAMING (ETG)
const gameCodeETGMICROGAMING = 103;
//PTGAMING (ETG)
const gameCodeETGPTGAMING = 104;
//RED TIGER (AWC)
const gameCodeAWCREDTIGER = 105;
//DREAM GAMING (Live)
const gameCodeDREAMGAMING = 106;
//DRAGOON SOFT (Slot)
const gameCodeDRAGOONSOFT = 107;
//FUNKY GAME (Slot)
const gameCodeFUNKYGAME = 108;
//KINGMAKER (AWC)
const gameCodeAWCKINGMAKER = 109;
//PG (AWC)
const gameCodeAWCPG = 110;
//Mario Club (THB)
const gameCodeMarioClubTHB = 111;
//GAMEPLAY (GS)
const gameCodeGSGAMEPLAY = 112;
//PEGASUS (ETG)
const gameCodeETGPEGASUS = 113;
//EBET (ETG)
const gameCodeETGEBET = 114;
//EVOLUTION GAMING S (ETG)
const gameCodeETGEVOLUTIONGAMINGS = 115;
//WM CASINO (ETG)
const gameCodeETGWMCASINO = 116;
//FUN GAMING (ETG)
const gameCodeETGFUNGAMING = 117;
//S Sport (Sport)
const gameCodeSSPORT = 118;
//LIVE22 (Direct)
const gameCodeLIVE22DIRECT = 119;
//918KISSH5 (Slot)
const gameCode918KISSH5 = 120;
//LIONKING (Slot)
const gameCodeLIONKING = 121;
//UUSlot (Slot)
const gameCodeUUSlot = 122;
//888KING H5 (Slot)
const gameCode888KINGH5 = 123;

//Theme Background Type
const backgroundTypeImage = 1;
const backgroundTypeColor = 2;

//Transfer Methods
const transferMethodOnlineTransfer = 1;
const transferMethodATM = 2;
const transferMethodCDM = 3;
//Payment gateway transfer method always start after 4 (due to report-deposit-withdrawal) listing
//M3Pay
const transferMethodPaymentGatewayM3Pay = 4;
//VanderPay
const transferMethodPaymentGatewayVanderPay = 5;
//Gtelco (2023/08/14 - Not Longer Support and Available)
//const transferMethodPaymentGatewayGtelco = 6;
//MiiiPay
const transferMethodPaymentGatewayMiiiPay = 7;
//i1Bayar
const transferMethodPaymentGatewayI1Bayar = 8;
//OK2Pay
const transferMethodPaymentGatewayOK2Pay = 9;
//BossCuci (Telco V3)
const transferMethodPaymentGatewayBossCuci = 10;
//FPay
const transferMethodPaymentGatewayFPay = 11;
//AllPay
const transferMethodPaymentGatewayAllPay = 12;
//WinPay
const transferMethodPaymentGatewayWinPay = 13;
//Surepay
const transferMethodPaymentGatewaySurepay = 14;
//SpeedPay
const transferMethodPaymentGatewaySpeedPay = 15;
//Pay2Win
const transferMethodPaymentGatewayPay2Win = 16;
//Capital Pay
const transferMethodPaymentGatewayCapitalPay = 17;
//PNOW
const transferMethodPaymentGatewayPNOW = 18;
//AUDPAY
const transferMethodPaymentGatewayAUDPAY = 19;
//MYPAY88
const transferMethodPaymentGatewayMYPAY88 = 20;
//RAPIDPAY
const transferMethodPaymentGatewayRAPIDPAY = 21;
//PowerPay88
const transferMethodPaymentGatewayPowerPay88 = 22;
//VPAY88
const transferMethodPaymentGatewayVPAY88 = 23;
//Acepay
const transferMethodPaymentGatewayAcepay = 24;
//Luckypay
const transferMethodPaymentGatewayLuckypay = 25;
//GGGPay
const transferMethodPaymentGatewayGGGPay = 26;
//DGPay
const transferMethodPaymentGatewayDGPay = 27;

module.exports = {
    baseURL,
    timeoutSeconds,
    fileSizeLimit,
    feathersJWTKey,

    api_authentication,
    api_companies,
    api_games,
    api_upload_games_image,
    api_upload_companies_image,
    api_upload_company_games_image,
    api_upload_company_banks_image,
    api_upload_company_games_sequence_image,
    api_upload_messages_image,
    api_upload_transactions_attachment,
    api_upload_whatsapp_bot_qr_image,
    api_upload_company_contacts_image,
    api_upload_bonus_image,

    bucket_path_messages,
    bucket_path_bonus,
    bucket_path_games,
    bucket_path_companies,
    bucket_path_company_games_sequence,
    bucket_path_company_custom_games,
    bucket_path_whatsapp_bot_qr_image,
    bucket_path_reports,

    file_upload_size_limit,

    feathers_error_code_claim_bonus_before_play,
    feathers_error_code_turnover_rollover_error,
    feathers_error_code_whatsapp_bot_qr_expired,
    feathers_error_code_room_not_found,

    keys_app_version,
    keys_user_object,
    keys_company_id,
    keys_device_id,
    keys_room_id,
    keys_welcome_notice,
    keys_dark_mode,

    defaultMainColor,
    defaultTopBarColor,
    defaultDrawerHeaderColor,
    darkBackgroundColor,
    defaultButtonColor,
    defaultCancelButtonColor,
    defaultButtonFontColor,

    darkModeBackgroundColor,
    darkModePaginationColor,
    darkModePaginationFontColor,
    darkModeTableFontColor,
    darkModeTableRowColor,

    buttonRedColor,
    buttonGreenColor,
    buttonOrangeColor,
    buttonIndigoColor,
    defaultPopupBorderColor,

    rowRedColor,

    textBlueColor,
    textRedColor,
    textGreenColor,
    textOrangeColor,
    textLightGreenColor,
    textPurpleColor,

    date_format_1,
    date_format_2,

    roleMCAdmin, roleMCManager, roleMCCS,
    roleBOAdmin, roleBOStaff, roleBOAgent, roleBOCS, roleBOKounter, roleBOHelper,
    rolePlayer,

    gameBehaviourCustomLayout,
    gameBehaviourOpenLink,
    gameBehaviourDownloadAPP,
    gameBehaviourCustomLayoutFEHost,
    gameBehaviourOpenLinkFEHost,
    gameBehaviourCustomLayoutCallGameLink,

    paymentGatewayMethodOnlineBanking,
    paymentGatewayMethodQRPayment,
    paymentGatewayMethodTelco,
    paymentGatewayMethodUSDTEtherium,
    paymentGatewayMethodUSDTTron,

    transactionStatusPending,
    transactionStatusProcessing,
    transactionStatusApproved,
    transactionStatusRejected,
    transactionStatusCancelled,

    transactionTypeDeposit, transactionTypeWithdrawal, transactionTypeBonus, transactionTypeManual, transactionTypeRebate, transactionTypeForfeited,
    transactionTypeCommission, transactionTypeLossCredit, transactionTypeBankTransfer, transactionTypeDepositFee,

    commissionPayoutStatusPending,
    commissionPayoutStatusPaid,
    commissionPayoutStatusClaim,

    bonusInputTypePercentage, bonusInputTypeFixedAmount, bonusInputTypeRandom,
    bonusInputRandomTypeRange, bonusInputRandomTypeAmount,

    bonusPlatformWeb, bonusPlatformApp, bonusPlatformAll,

    bonusImageTypeNormal, bonusImageTypeLong,

    rebateTypeWinLoss, rebateTypeTotalBet,

    affiliateCommissionOptionManual, affiliateCommissionOptionFirstDepositOnly,
    affiliateCommissionTypeWinLoss, affiliateCommissionTypeTotalBet,

    roomMessageModeLive, roomMessageModeWhatsApp,

    messagesIndicatorWA, messagesIndicatorWASMS, messagesIndicatorLIVE,

    contactTypeWhatsApp, contactTypeTelegram, contactTypeWechat, contactTypeCustom,

    userStatusActive,
    userStatusEmergency,
    userStatusSuspended,
    userStatusInactive,

    transactionRemarksFundNoReceived,
    transactionRemarksOther,

    chatCommandTypeNotification, chatCommandTypeChat, chatCommandTypeWhatsappReferral, chatCommandTypeLineReply,

    reportStatusInProgress, reportStatusCompleted, reportStatusFailed,

    defaultCountryCode, countryCodeTH, countryCodeVN, countryCodeSG, countryCodeID, countryCodeUS, countryCodeKH, countryCodeBD, countryCodeIN, countryCodeMM,
    countryCodeAU, countryCodeBN,

    reportDataTypeTransaction, reportDataTypeRegistrationDate,

    gameCodeXE88, gameCodeMarioClub, gameCodeKiss918, gameCodePussy888, gameCodeMega888, gameCodeEvo888, gameCodeBGGaming, gameCodePlaytech,
    gameCodeVPower, gameCodeJoker, gameCodeIBC, gameCodeGreatWall, gameCodeAllbet, gameCodeALBBEG93, gameCodeAWCSV388, gameCodeAWCSEXYBCRT, gameCodeAWCHORSEBOOK,
    gameCodeM8Bet, gameCodeETGASIAGAMING, gameCodeETGCLUBSUNCITY, gameCodeETGACE333, gameCodeETGSBO, gameCodeETG918KAYA, gameCodeETGPLAYBOY,
    gameCodeETGJDB, gameCodeETGJILI, gameCodeETGLIVE22, gameCodeETGKING855, gameCodeETGCQ9, gameCodeETGHABANERO, gameCodeETGLIONKING,
    gameCodeETGPLAYNGO, gameCodeETGPRAGMATIC, gameCodeETGSPADEGAMING, gameCodeETGSAGAMING, gameCodeEkor, gameCodeETG3WIN8, gameCodeFunhouse,
    gameCodeETGFUNKYGAME, gameCodeETGDRAGOONSOFT, gameCodeETGGAMEPLAY, gameCodeBooongo, gameCodeAce333, gameCodeKing855, gameCodeSBO,
    gameCodePragmaticSlot, gameCodePragmaticLive, gameCodeALBB28Win, gameCodeSAGaming, gameCodeALBB28Win2, gameCodeSBO2, gameCodeALBBCoba8,
    gameCodePragmaticSSlot, gameCodePragmaticSLive, gameCodeEvolution, gameCodeSpadeGaming, gameCodeAWCSPADE, gameCodeAWCE1SPORT,
    gameCodeJILI, gameCodeClubSuncity, gameCodeAWCJILI, gameCodeAWCJDB, gameCodeAWCFC, gameCodeAWCAWS, gameCodeAWCVENUS, gameCodeAWCJDBFISH,
    gameCodeAWCYL, gameCodeYEEBET, gameCodeAGGaming, gameCode3WIN8, gameCodeCQ9, gameCodeLIVE22, gameCode918KAYA, gameCodePLAYBOY, gameCodeHABANERO,
    gameCode918KISS2, gameCodeETGXE88, gameCodeETGMARIOCLUB, gameCodeETGJOKER, gameCodeETGM8SPORT, gameCodeETGYLFISHING,
    gameCodeETGMEGAWIN, gameCodeETGEVO888, gameCodeETGMEGA888, gameCodeETGGREATWALL, gameCodeETGGRANDDRAGONLOTTO,
    gameCodeETGGRANDDRAGONMKT, gameCodeETGKINGMAKER, gameCodeETGPLAYTECH, gameCodeETGSV388, gameCodeETGSEXYBACCARAT,
    gameCodeETG918KISS, gameCodeETGPUSSY888, gameCodeETGVPOWER, gameCodeETGALLBET, gameCodeETG918KISSH5, gameCodeAWCBG,
    gameCodeETGIBC, gameCodeETGBG, gameCodeETGMPOKER, gameCodeETGASIAGAMINGS, gameCodeETGOBET33, gameCodeETGMICROGAMING, gameCodeETGPTGAMING,
    gameCodeAWCREDTIGER, gameCodeDREAMGAMING, gameCodeDRAGOONSOFT, gameCodeFUNKYGAME, gameCodeAWCKINGMAKER, gameCodeAWCPG, gameCodeMarioClubTHB,
    gameCodeGSGAMEPLAY, gameCodeETGPEGASUS, gameCodeETGEBET, gameCodeETGEVOLUTIONGAMINGS, gameCodeETGWMCASINO, gameCodeETGFUNGAMING, gameCodeSSPORT,
    gameCodeLIVE22DIRECT, gameCode918KISSH5, gameCodeLIONKING, gameCodeUUSlot, gameCode888KINGH5,

    backgroundTypeImage,
    backgroundTypeColor,

    transferMethodOnlineTransfer, transferMethodATM, transferMethodCDM, transferMethodPaymentGatewayM3Pay, transferMethodPaymentGatewayVanderPay,
    transferMethodPaymentGatewayMiiiPay, transferMethodPaymentGatewayI1Bayar, transferMethodPaymentGatewayOK2Pay,
    transferMethodPaymentGatewayBossCuci, transferMethodPaymentGatewayFPay, transferMethodPaymentGatewayAllPay, transferMethodPaymentGatewayWinPay,
    transferMethodPaymentGatewaySurepay, transferMethodPaymentGatewaySpeedPay, transferMethodPaymentGatewayPay2Win, transferMethodPaymentGatewayCapitalPay,
    transferMethodPaymentGatewayPNOW, transferMethodPaymentGatewayAUDPAY, transferMethodPaymentGatewayMYPAY88, transferMethodPaymentGatewayRAPIDPAY, transferMethodPaymentGatewayPowerPay88, transferMethodPaymentGatewayVPAY88, transferMethodPaymentGatewayAcepay, transferMethodPaymentGatewayLuckypay,
    transferMethodPaymentGatewayGGGPay, transferMethodPaymentGatewayDGPay
}