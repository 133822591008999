import { Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { StyledTableCell, StyledTablePagination, StyledTableRow } from "../../components/dataGrid/tableGrid"

export default function CustomerGameLog({ user }) {
    //Pagination Constants
    const rowsPerPage = 20

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    //States
    const [page, setPage] = useState(0)
    const [list, setList] = useState([])

    //Ref
    const listRef = useRef(list)

    //DATA GRID METHODS
    const getTotal = () => {
        return list?.length ?? 0
    }
    const getRows = () => {
        return list?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((e, index) => {
            let createdAt = e?.createdAt ? Utils.shared.convertDateToString(Date.parse(e?.createdAt), Constants.date_format_2) : ''
            let game = e?.game?.label ?? '-'
            let processTime = e?.processTime ?? '-'
            let balanceIn = Utils.shared.getAmountString(e?.balanceIn ?? 0)
            let balanceOut = Utils.shared.getAmountString(e?.balanceOut ?? 0)
            let logs = e?.logs ?? ''
            let gamePointRate = e?.gamePointRate ?? ''

            if (logs.length > 0) {
                return (
                    <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{createdAt}</StyledTableCell>
                        <StyledTableCell colSpan={5} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{logs}</StyledTableCell>
                    </StyledTableRow>
                )
            } else {
                return (
                    <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{createdAt}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{game}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{processTime}</StyledTableCell>
                        <StyledTableCell sx={{ backgroundColor: '#388E3C', color: 'white' }}>{balanceIn}</StyledTableCell>
                        <StyledTableCell sx={{ backgroundColor: '#f54242', color: 'white' }}>{balanceOut}</StyledTableCell>
                        <StyledTableCell>{gamePointRate}</StyledTableCell>
                    </StyledTableRow>
                )
            }
        }) ?? []
    }

    //API METHODS
    const getList = () => {
        FeathersClient.create('get-player-games-logs', {
            'company': userInfo?.company?._id,
            'user': user._id,
        }, null, setAlertDialog, (response) => {
            let gamesLogs = response?.gamesLogs ?? []
            setList([...[], ...gamesLogs])
            listRef.current = [...[], ...gamesLogs]

            setPage(0)
        })
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('games-logs', 'created', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === userInfo?.company?._id) &&
                (data?.user?._id === user?._id)) {
                setList([data, ...listRef.current])
                listRef.current = [data, ...listRef.current]
            }
        })

        FeathersClient.listen('games-logs', 'patched', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                let tempList = [...listRef.current]
                tempList[index] = data
                listRef.current = tempList
                setList(tempList)
            }
        })

        FeathersClient.listen('games-logs', 'removed', 'customer', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)
            setList(tempList)
            listRef.current = tempList
        }
    }

    useEffect(() => {
        if (user) {
            getList(0, true)
            startSocket()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Created Date/Time</StyledTableCell>
                                        <StyledTableCell>Game</StyledTableCell>
                                        <StyledTableCell>Process Time</StyledTableCell>
                                        <StyledTableCell>In</StyledTableCell>
                                        <StyledTableCell>Out</StyledTableCell>
                                        <StyledTableCell>Game Point Rate</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                        <StyledTableCell component="th" scope="row" align="center" colSpan={6}>
                                            No Record Found
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            count={getTotal()}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            onPageChange={(event, newPage) => {
                                setPage(newPage)
                            }}
                            sx={{
                                backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#2f3539' }),
                                color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
                                svg: {
                                    color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })
                                }
                            }}
                        />
                    </Paper>
                </Stack>
            </div>
        )
    } else {
        return null
    }
}