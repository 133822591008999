import { DataGrid } from '@mui/x-data-grid'
import React from "react"
import { styled } from '@mui/material/styles'
import Constants from '../../constants'

function BaseDataGrid({
    columns,
    rows = [],
    isLoading = false,
    pageSize = 10,
    total,
    page = 0,
    onPageChange,
    getRowId = null,
    color = 'black',
    headerHeight = 56,
    onRowClick = null,
    getRowClassName = null,
    pagination = true,
    onSelectionModelChange = null,
    selectionModel = [],
    enableSelection = false,
    isRowSelectable = null,
    disableHoverColor = false,
}) {
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
            fontSize: '12px',
            fontWeight: 'bold',
            backgroundColor: Constants.darkBackgroundColor,
            svg: {
                color: 'white'
            },
            color: 'white'
        },
        '& .MuiTablePagination-displayedRows': {
            color: color,
        },
        '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 0,
            },
            fontSize: '12px'
        },
        '& .MuiDataGrid-actionsCell': {
            svg: {
                color: 'black'
            }
        },
        color: color,
    }))

    return (
        <StyledDataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            page={page}
            rowCount={total ?? (rows ?? []).length}
            autoHeight={true}
            density='compact'
            loading={isLoading}
            disableSelectionOnClick={!enableSelection}
            checkboxSelection={enableSelection}
            disableColumnMenu={true}
            rowsPerPageOptions={[pageSize]}
            getRowId={getRowId}
            headerHeight={headerHeight}
            getRowHeight={() => 'auto'}
            hideFooter={!pagination}
            selectionModel={selectionModel}
            onPageChange={(page, details) => {
                onPageChange(page, details)
            }}
            onRowClick={onRowClick}
            onSelectionModelChange={onSelectionModelChange}
            isRowSelectable={isRowSelectable}
            getRowClassName={getRowClassName}
            sx={{
                color: color,
                label: {
                    color: color
                }
            }}
        />
    )
}

export default BaseDataGrid