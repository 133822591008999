import io from 'socket.io-client';
import feathers from "@feathersjs/client";
import Constants from '../constants';

// Socket.io is exposed as the `io` global.
const socket = io(`${Constants.baseURL}`, {
    transports: ['websocket'],
    upgrade: false,
    timeout: 120000,
})

// @feathersjs/client is exposed as the `feathers` global.
const app = feathers();

app.configure(feathers.socketio(socket, {
    timeout: 120000,
}))
app.configure(feathers.authentication({
    storage: window.localStorage,
    storageKey: Constants.feathersJWTKey,
    jwtStrategy: 'jwt'
}))

export default app 