import { MenuItem, Checkbox, ListItemText, FormControl, Select, InputLabel, OutlinedInput, FormHelperText, Box } from '@mui/material'
import Constants from '../../constants'
import parse from 'html-react-parser'
import Utils from '../../utils/utils'

function Dropdown({ 
    title, 
    value = '', 
    placeholder = 'Please Select', 
    helperText = '', 
    items = [], 
    customItems = null, 
    titleColor = Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }), 
    isMultiSelect = false, 
    disabled = false, 
    showError = false, 
    errorText = '', 
    onSelectChanged, onSubmit = null 
}) {
    return (
        <FormControl fullWidth={true} error={showError} disabled={disabled} size='small' sx={{
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: titleColor,
                },
                "&:hover fieldset": {
                    borderColor: 'gray'
                },
                "&.Mui-disabled fieldset": {
                    borderColor: 'gray',
                },
                "& fieldset": {
                    borderColor: titleColor
                }
            },
            "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: titleColor,
            },
        }}>
            <InputLabel id="multiple-checkbox-label" sx={{
                color: disabled ? 'gray' : titleColor,
                '&.Mui-focused': {
                    color: disabled ? 'gray' : titleColor
                }
            }} shrink={true}>{title}</InputLabel>
            <Select
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                multiple={isMultiSelect}
                value={value}
                placeholder={placeholder}
                onChange={(e) => {
                    onSelectChanged(e.target.value)
                }}
                displayEmpty={true}
                input={<OutlinedInput label={title} value={value} placeholder={placeholder} notched={true} sx={{
                    color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
                    svg: {
                        color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' })
                    }
                }} />}
                renderValue={(selected) => {
                    var valueResult = ''

                    if (isMultiSelect) {
                        if (selected.length > 0) {
                            let tempList = items?.filter((e) => selected?.indexOf(e?.key) > -1) ?? []
                            if (tempList.length > 0) {
                                valueResult = tempList.map((e) => e?.value ?? '')?.join(', ') ?? ''
                            }

                            return parse(valueResult)
                        }
                    } else {
                        if (selected) {
                            let selectedItem = items.find((e) => selected === e?.key)
                            valueResult = selectedItem?.value ?? ''

                            return parse(valueResult)
                        }
                    }

                    return (
                        <Box sx={{ color: Utils.shared.getColorByMode({ lightColor: 'rgba(0, 0, 0, .4)', darkColor: 'rgba(255, 255, 255, .4)' }) }}>
                            {placeholder}
                        </Box>
                    )
                }}
            >
                {
                    //Check if have customItems supplied
                    customItems ? customItems : items.map((e) => (
                    <MenuItem dense={true} divider={true} key={e?.key} value={e?.key} >
                        <Checkbox size='small' sx={{
                            '&.Mui-checked': {
                                color: Constants.buttonGreenColor,
                            },
                            paddingLeft: '5px'
                        }} checked={isMultiSelect ? (value?.indexOf(e?.key) > -1) : (e?.key === value)} />
                        <ListItemText primary={parse(e?.value)} />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText sx={{ color: showError ? 'red' : titleColor }}>{showError ? errorText : helperText}</FormHelperText>
        </FormControl >
    )
}

export default Dropdown