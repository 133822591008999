import { Box, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import Constants from '../../constants'
import Utils from "../../utils/utils";

export const StyledTableCell = styled(TableCell)(({ color }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: Constants.darkBackgroundColor,
        color: color ?? 'white',
        fontSize: 12,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        color: color ?? Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' }),
        svg: {
            color: color ?? Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' }),
        }
    },
}));
export const StyledTableCellMedium = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: Constants.darkBackgroundColor,
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ color }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: Utils.shared.getColorByMode({ lightColor: color ?? 'rgba(0, 0, 0, 0.02)', darkColor: '#757575' }),
    },
    backgroundColor: color ?? Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' }),
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&.MuiTableRow-hover': {
        '&:hover': {
            backgroundColor: color ?? Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' }),
        },
    },
}))

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
}))

export function StyledTableSortLabel({ title, active = false, direction = 'asc', onClick }) {
    return (
        <TableSortLabel sx={{
            svg: {
                color: 'white'
            },
            '&.MuiButtonBase-root': {
                '&.MuiTableSortLabel-root': {
                    '&.Mui-active': {
                        '& .MuiTableSortLabel-icon': {
                            color: 'white'
                        },
                    }
                }
            }
        }} active={active} direction={direction} onClick={onClick}>
            <Box sx={{ color: 'white' }}>
                {title}
            </Box>
        </TableSortLabel>
    )
}

export function BaseTable({
    headerTitles = [],
    headerCells = [],
    rowCells = [],
    rowsPerPage,
    total = 0,
    page = 0,
    onPageChange,

}) {
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {
                                (headerTitles.length > 0) ? headerTitles.map((e) => {
                                    return (
                                        <StyledTableCell align={e === 'Actions' ? 'right' : 'left'}>{e}</StyledTableCell>
                                    )
                                }) : headerCells ?? null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rowCells.length > 0 ? rowCells : (
                                <StyledTableRow key='norecordrow'>
                                    <StyledTableCell component="th" scope="row" align="center" colSpan={headerTitles.length > 0 ? headerTitles.length : headerCells.length}>
                                        No Record Found
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledTablePagination
                rowsPerPageOptions={[rowsPerPage]}
                count={total}
                rowsPerPage={rowsPerPage}
                component="div"
                page={page}
                onPageChange={onPageChange}
                sx={{
                    backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#2f3539' }),
                    color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
                    svg: {
                        color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })
                    }
                }}
            />
        </Paper>
    )
}