import React, { useContext, useEffect, useState } from "react"
import { TextField, InputAdornment, Button, Menu, MenuItem } from '@mui/material'
import { AlertDialogContext, UserInfoContext } from "../../App"
import useScreenType from "react-screentype-hook"
import { KeyboardArrowDown } from "@mui/icons-material"
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import Utils from "../../utils/utils"

export default function InputTextField({
    title,
    value,
    placeholder = '',
    helperText,
    titleColor = Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
    /**
    * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
    */
    type = 'text',
    showError = false,
    errorText = '',
    disabled = false,
    prefix = null,
    suffix = null,
    fullWidth = true,
    min = null, //Only for number
    max = null, //Only for number
    showStepper = false, // Only for number
    onTextChanged,
    onPhoneCodeChanged = null,
    onSubmit = null,
}) {
    //Contexts
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { userInfo } = useContext(UserInfoContext)
    const screenType = useScreenType()
    const companyInfo = useCompanyInfo()

    //States
    const [phoneCode, setPhoneCode] = useState('')

    // For Phone Codes Only
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handlePhoneCodeDropDownClick = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget)
        }
    }

    const getPrefix = () => {
        switch (type) {
            case 'telWithPhoneCode':
                let phoneCodes = companyInfo?.phoneCodes ?? []
                if (phoneCodes.length > 0) {
                    if (phoneCodes.length === 1) {
                        return (
                            <InputAdornment position="start" >
                                <div style={{ color: titleColor }} >
                                    {phoneCodes[0]}
                                </div>
                            </InputAdornment>
                        )
                    } else {
                        return (
                            <div>
                                <Button
                                    variant="text"
                                    sx={{ backgroundColor: 'transparent', color: titleColor, paddingLeft: 0 }}
                                    disableElevation
                                    onClick={handlePhoneCodeDropDownClick}
                                    endIcon={<KeyboardArrowDown />}
                                >
                                    {phoneCode}
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    {phoneCodes.map((e, index) => {
                                        return (
                                            <MenuItem key={`phoneCodeMenuItem${index}`} onClick={() => {
                                                setAnchorEl(null)
                                                setPhoneCode(e)
                                                onPhoneCodeChanged(e)
                                            }} disableRipple>
                                                {e}
                                            </MenuItem>
                                        )
                                    })}
                                </Menu>
                            </div>
                        )
                    }
                } else {
                    return null
                }
            default:
                return prefix
        }
    }

    const getSuffix = () => {
        if (suffix) {
            return (
                <InputAdornment position="end">
                    {suffix}
                </InputAdornment>
            )
        } else {
            return null
        }
    }

    const getInputLabelProps = () => {
        switch (type) {
            case 'file':
                return {
                    style: {
                        color: disabled ? 'gray' : titleColor
                    },
                    shrink: true,
                }
            default:
                return {
                    style: {
                        color: disabled ? 'gray' : titleColor
                    },
                    shrink: true,
                }
        }
    }

    //USE EFFECT
    useEffect(() => {
        //Check type and assign default value if any
        switch (type) {
            case 'telWithPhoneCode':
                let phoneCodes = companyInfo?.phoneCodes ?? []
                //By default select the first if no selected
                if (!phoneCode) {
                    if (phoneCodes.length > 0) {
                        setPhoneCode(phoneCodes[0])

                        if (onPhoneCodeChanged) {
                            onPhoneCodeChanged(phoneCodes[0])
                        }
                    } else {
                        setPhoneCode('')

                        if (onPhoneCodeChanged) {
                            onPhoneCodeChanged('')
                        }
                    }
                }
                break
            default:
                break
        }
    }, [])

    return (
        <TextField
            label={title}
            value={value ?? ''}
            variant='outlined'
            type={(type === 'pin' || type === 'telWithPhoneCode') ? 'tel' : type}
            helperText={showError ? errorText : helperText}
            placeholder={placeholder}
            fullWidth={fullWidth}
            error={showError}
            disabled={disabled}
            onChange={(e) => {
                if (type === 'number' && !screenType.isMobile) {
                    let dec = e.target.value.indexOf(".")
                    let tooLong = e.target.value.length > dec + 3
                    let invalidNum = isNaN(parseFloat(e.target.value))

                    if ((dec >= 0 && tooLong) || invalidNum) {
                        e.target.value = e.target.value.slice(0, -1)
                    }
                }

                onTextChanged(e.target.value)
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    if (onSubmit) {
                        onSubmit()
                    }
                }
            }}
            sx={{
                input: {
                    color: disabled ? 'gray' : titleColor,
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        '-webkit-appearance': showStepper ? null : 'none',
                    },
                },
                "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                        borderColor: titleColor
                    },
                    "&:hover fieldset": {
                        borderColor: 'gray'
                    },
                    "&.Mui-disabled fieldset": {
                        borderColor: 'gray',
                    },
                    "& fieldset": {
                        borderColor: titleColor
                    }
                },
                "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: titleColor,
                },
            }}
            InputLabelProps={getInputLabelProps()}
            InputProps={
                {
                    style: {
                        color: "white",
                    },
                    startAdornment: getPrefix(),
                    endAdornment: getSuffix(),
                    inputProps: {
                        min: min,
                        max: max,
                    }
                }
            }
            FormHelperTextProps={
                {
                    style: {
                        color: showError ? 'red' : titleColor
                    }
                }
            }
            size='small'
            onWheel={(e) => {
                e.target.blur()
            }}
        />
    )
}