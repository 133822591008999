import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BaseButton from "../components/button/button";

export default function PageNotFound() {
    let navigate = useNavigate()

    return (
        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor: 'rgba(225, 225, 225, 1.0)' }}>
            <Stack spacing='20px' sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <img src={`${process.env.PUBLIC_URL}/images/icons/404-error.png`} alt='404' height='100px' width='auto' />
                <h2>Page Not Found</h2>
                <h7>The page you we're looking for does not exist</h7>
                <BaseButton title='HOME' fullWidth={false} onTap={() => {
                    navigate('/')
                }} />
            </Stack>
        </Stack>
    )
}