import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import AlertDialog from "./components/dialogs/alertDialog"
import BasePopup from './components/popup/basePopup'
import { Box, CircularProgress, Dialog } from '@mui/material'
import { Provider } from 'figbird'
import client from './feathers/feathers'
import FeathersClient from './feathers/feathersClient'
import AppTopBar from './components/appBar/appTopBar'
import Constants, { keys_dark_mode } from './constants'
import './styles/main.scss'
import useWindowSize from './hooks/useWindowSize'
import { SnackbarProvider } from './components/snackBar/baseSnackBar'
import Utils from './utils/utils';
import crypto from 'crypto-js'
import CustomerDetails from './pages/customerList/customerDetails';

function App() {
  let navigate = useNavigate()
  const search = useLocation().search
  const t = new URLSearchParams(search).get('t') //Encrypted token for mc auto login
  const c = new URLSearchParams(search).get('c') //Company ID for mc auto login

  const [width, height] = useWindowSize()

  //States
  const [userInfo, setUserInfo] = useState(null)
  const [listing, setListing] = useState(null)
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  //Alert Dialog
  const [alertDialog, setAlertDialog] = useState({
    title: 'Error',
    description: '',
    closeButtonTitle: 'OK',
    isOpen: false,
    isConfirmation: false,
    onConfirmed: () => { },
    onClosed: () => { }
  })

  //Popup
  const [popup, setPopup] = useState({
    children: null,
    showCloseButton: false,
    isOpen: false,
    onClosed: () => { }
  })
  const [customerDetailsPopup, setCustomerDetailsPopup] = useState(null) //Used to show or hide customer details popup

  //Loading
  const loadingDiv = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', color: Constants.defaultDrawerHeaderColor, backgroundColor: 'transparent' }}>
      <CircularProgress color='inherit' size={50} thickness={6} style={{ backgroundColor: 'transparent' }} />
    </div>
  )

  //USE EFFECT
  useEffect(() => {
    //Initial calling
    //Get listing
    Utils.shared.getBackendListing(setAlertDialog, (resultListing) => {
      setListing(resultListing)

      //Check if is auto login from MC
      if ((t && t.length > 0) && (c && c.length > 0)) {
        //MC Auto Login
        let key = crypto.enc.Utf8.parse('thebestsecretkeythebestsecretkey')
        let iv = crypto.enc.Utf8.parse('thebestsecretkey')
        let bytes = crypto.AES.decrypt(t, key, { iv: iv })
        let token = bytes.toString(crypto.enc.Utf8)
        Utils.shared.storeLocalStorage(Constants.feathersJWTKey, token)

        FeathersClient.reauthenticate((response) => {
          FeathersClient.get('companies', c, null, setAlertDialog, (company) => {
            Utils.shared.storeLocalStorage(Constants.keys_company_id, company?._id)

            let userWithCompany = {
              ...response?.user,
              company: company
            }

            setUserInfo(userWithCompany)
            setIsInitialLoading(false)

            Utils.shared.getRouteByRoleType(userWithCompany, navigate)
          }, null, null, false)
        }, (error) => {
          setUserInfo(null)
          setIsInitialLoading(false)

          navigate('/login', { replace: true })
        }, null)
      } else {
        //Normal flow
        FeathersClient.reauthenticate((response) => {
          //Check if user is from MC auto login
          if (response?.user?.company) {
            //Have company, means is BO user
            setUserInfo(response?.user)
            setIsInitialLoading(false)

            Utils.shared.getRouteByRoleType(response?.user, navigate)
          } else {
            //No company, means is MC auto login user
            let companyId = Utils.shared.retrieveLocalStorage(Constants.keys_company_id)
            if (companyId) {
              FeathersClient.get('companies', companyId, null, setAlertDialog, (company) => {
                Utils.shared.storeLocalStorage(Constants.keys_company_id, company?._id)

                let userWithCompany = {
                  ...response?.user,
                  company: company
                }

                setUserInfo(userWithCompany)
                setIsInitialLoading(false)

                Utils.shared.getRouteByRoleType(userWithCompany, navigate)
              }, null, null, false)
            } else {
              //No company ID stored, invalid auto login session
              setUserInfo(null)
              setIsInitialLoading(false)

              navigate('/login', { replace: true })
            }
          }
        }, (error) => {
          setUserInfo(null)
          setIsInitialLoading(false)
          Utils.shared.storeLocalStorage(Constants.keys_company_id, null)

          navigate('/login', { replace: true })
        }, null)
      }
    })
  }, [])

  //For dark mode auto
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const cachedDarkMode = Utils.shared.retrieveLocalStorage(keys_dark_mode)

    const handleDarkModeChange = (event) => {
      if (cachedDarkMode === 'auto') {
        window.location.reload()
      }
    };

    darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
    };
  }, []);

  return (
    <AlertDialogContext.Provider value={{ alertDialog, setAlertDialog }}>
      <PopupContext.Provider value={{ popup, setPopup }}>
        <div style={{ backgroundColor: Utils.shared.getColorByMode({lightColor: 'white', darkColor: 'black'}) }}>
          {
            isInitialLoading ? (
              <div style={{ width: '100vw', height: '100vh' }}>
                {loadingDiv}
              </div>
            ) :
              (
                <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
                  <ListingContext.Provider value={{ listing, setListing }}>
                    <CustomerDetailsPopupContext.Provider value={{ customerDetailsPopup, setCustomerDetailsPopup }}>
                      <SnackbarProvider>
                        <Provider feathers={client} >
                          <div className='full-height'>
                            <div className='column'>
                              <AppTopBar />
                              <div style={{ height: (height - (userInfo ? 68 : 0)), marginTop: userInfo ? 68 : 0, width: '100%' }}>
                                <Outlet />
                              </div>
                            </div>
                          </div>

                          {/* Player Details Popup */}
                          <Dialog fullScreen open={customerDetailsPopup != null} onClose={() => {
                            setCustomerDetailsPopup(null)
                          }} PaperProps={{
                            sx: {
                                backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#505050' })
                            }
                        }}>
                            <CustomerDetails userId={customerDetailsPopup?._id} />
                          </Dialog>
                        </Provider>
                      </SnackbarProvider>
                      <BasePopup />
                      <div id='spinner' className='modal'>
                        <Box sx={{
                          position: 'absolute',
                          bgcolor: 'transparent',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 'calc(50% - 20px)',
                          left: 'calc(50% - 20px)'
                        }} >
                          {loadingDiv}
                        </Box>
                      </div>
                    </CustomerDetailsPopupContext.Provider>
                  </ListingContext.Provider>
                </UserInfoContext.Provider>
              )
          }
          <AlertDialog />
        </div>
      </PopupContext.Provider>
    </AlertDialogContext.Provider >
  );
}

export default App
export const UserInfoContext = React.createContext({ userInfo: null, setUserInfo: () => { } })
export const ListingContext = React.createContext({ listing: null, setListing: () => { } })
export const CustomerDetailsPopupContext = React.createContext({ customerDetailsPopup: null, setCustomerDetailsPopup: () => { } })
export const AlertDialogContext = React.createContext({
  alertDialog: {
    title: 'Error',
    description: '',
    closeButtonTitle: 'OK',
    isOpen: false,
    isConfirmation: false,
    onConfirmed: () => { },
    onClosed: () => { }
  }, setAlertDialog: () => { }
})
export const PopupContext = React.createContext({
  popup: {
    children: null,
    showCloseButton: false,
    isOpen: false,
    onClosed: () => { }
  }, setPopup: () => { }
})
