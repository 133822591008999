import { Stack } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import BaseDataGrid from "../../components/dataGrid/dataGrid"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import InputTextField from "../../components/textfield/inputTextField"
import { useSnackbar } from '../../components/snackBar/baseSnackBar'

export default function CustomerCase({ user }) {
    //Pagination Constants
    const rowPerPage = 20

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const snackBar = useSnackbar()

    //States
    const [values, setValues] = useState({
        case: '',
        caseError: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [list, setList] = useState([])
    const [listPaginationData, setListPaginationData] = useState(null)
    const [result, setResult] = useState(null)

    //Ref
    const listPaginationDataRef = useRef(listPaginationData)
    const listRef = useRef(list)
    const valuesRef = useRef(values)

    //DATA GRID METHODS
    const getTotal = () => {
        return listPaginationData?.total ?? 0
    }
    const getColumns = () => {
        return [
            {
                field: 'updatedAt',
                headerName: 'Date/Time',
                minWidth: 100,
                type: 'dateTime',
                flex: 1,
                headerClassName: 'columnHeaderStyle',
                valueGetter: (params) => (params.row?.createdAt ? Utils.shared.convertDateToString(Date.parse(params.row?.updatedAt), Constants.date_format_2) : ''),
            },
            {
                field: 'case',
                headerName: 'Case',
                minWidth: 100,
                flex: 1,
                headerClassName: 'columnHeaderStyle',
            },
            {
                field: 'createdBy',
                headerName: 'Created By',
                minWidth: 120,
                flex: 1,
                headerClassName: 'columnHeaderStyle',
                valueGetter: (params) => (params.row.createdBy?.fullName ?? '-')
            }
        ]
    }

    //API METHODS
    const getList = (index, isRefresh = false) => {
        if ((index * rowPerPage) >= list.length || isRefresh) {
            setIsLoading(true)

            let query = {
                '$skip': (index * rowPerPage),
                '$limit': rowPerPage,
                'company': userInfo?.company?._id,
                'user': user._id,
                '$sort': {
                    'updatedAt': '-1',
                },
                '$populate': [
                    'company',
                    'user',
                    'createdBy',
                ],
            }

            FeathersClient.find('cases', {
                query: query
            }, setAlertDialog, (response) => {
                let bets = response?.data ?? []
                if (isRefresh) {
                    setList([...[], ...bets])
                    listRef.current = [...[], ...bets]
                } else {
                    setList(list => [...list, ...bets])
                    listRef.current = [...list, ...bets]
                }

                setListPaginationData(response)
                listPaginationDataRef.current = response

                setPage(index)
            }, null, () => {
                setIsLoading(false)
            }, false)
        } else {
            setPage(index)
        }
    }
    const createNewCase = () => {
        FeathersClient.create('cases', {
            company: userInfo.company._id,
            user: user._id,
            case: values.case,
        }, {
            query: {
                '$populate': [
                    'company',
                    'user',
                    'createdBy',
                ],
            }
        }, setAlertDialog, (response) => {
            setValues(values => ({
                ...values,
                case: '',
                caseError: '',
            }))
            snackBar.showMessage('Case added successfully')
        })
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('cases', 'created', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === userInfo?.company?._id)) {
                setList([data, ...listRef.current])
                listRef.current = [data, ...listRef.current]

                setListPaginationData({
                    ...listPaginationDataRef.current,
                    total: listPaginationDataRef.current.total + 1,
                })
                listPaginationDataRef.current.total = listPaginationDataRef.current.total + 1
            }
        })

        FeathersClient.listen('cases', 'patched', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                let tempList = [...listRef.current]
                tempList[index] = data
                listRef.current = tempList
                setList(tempList)
            }
        })

        FeathersClient.listen('cases', 'removed', 'customer', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)
            setList(tempList)
            listRef.current = tempList

            setListPaginationData({
                ...listPaginationDataRef.current,
                total: listPaginationDataRef.current.total - 1,
            })
            listPaginationDataRef.current.total = listPaginationDataRef.current.total - 1
        }
    }

    useEffect(() => {
        if (user) {
            getList(0, true)
            startSocket()
        }
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <InputTextField title='Case' placeholder={'Enter Case Name'} value={values.case} errorText={values.caseError} showError={values.caseError.length > 0} onTextChanged={(value) => {
                        setValues(values => ({
                            ...values,
                            case: value,
                            caseError: '',
                        }))
                    }} />
                    <BaseButton title='Save' onTap={() => {
                        //Create new case
                        if (values.case.length === 0) {
                            setValues(values => ({ ...values, caseError: 'Case name is mandatory' }))
                            return
                        }

                        createNewCase()
                    }} />
                    <BaseDataGrid columns={getColumns()} rows={isLoading ? [] : list} isLoading={isLoading} pageSize={rowPerPage} getRowId={(row) => row._id ?? '0'} total={getTotal()} page={page} onPageChange={(page, details) => {
                        getList(page, false)
                    }} />
                </Stack>
            </div>
        )
    } else {
        return null
    }
}