import { Divider, Stack, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Box } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import BaseDateTimePicker from "../../components/datePicker/datePicker"
import Dropdown from "../../components/dropdown/dropdown"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { StyledTableCell, StyledTablePagination, StyledTableRow } from "../../components/dataGrid/tableGrid"

export default function CustomerBetHistory({ user }) {
    //Pagination Constants
    const rowsPerPage = 20

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    //States
    const [values, setValues] = useState({
        game: 999,
        currentSearchGame: 999,
        startDate: Date.now(),
        currentSearchStartDate: null,
        endDate: Date.now(),
        currentSearchEndDate: null,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [list, setList] = useState([])
    const [listPaginationData, setListPaginationData] = useState(null)
    const [result, setResult] = useState(null)

    //Ref
    const listPaginationDataRef = useRef(listPaginationData)
    const listRef = useRef(list)
    const valuesRef = useRef(values)

    //DATA GRID METHODS
    const getTotal = () => {
        return listPaginationData?.total ?? 0
    }
    const getRows = () => {
        return list?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
            let createdAt = row?.createdAt ? Utils.shared.convertDateToString(Date.parse(row?.createdAt), Constants.date_format_2) : ''
            let betAt = row?.betAt ? Utils.shared.convertDateToString(Date.parse(row?.betAt), Constants.date_format_2) : createdAt
            let betAmount = Utils.shared.getAmountString(row?.betAmount)
            let winAmount = Utils.shared.getAmountString(row?.winAmount)

            const getGameDisplay = () => {
                let gameName = row?.game?.label ?? ''
                let miniGame = row?.miniGame ?? ''
                if (miniGame.length > 0) {
                    return (
                        <Stack>
                            <Box><b>{gameName}</b></Box>
                            <Box>{miniGame}</Box>
                        </Stack>
                    )
                } else {
                    return <b>{gameName}</b>
                }
            }

            //Row Color
            if ((row?.type === 2 || row?.type === 6 || row?.type === 10) ||
                (row?.type === 4 && (row?.amount?.isNegative ?? false))) {
                return (
                    <TableRow key={`row${index}`} hover={true} sx={{ backgroundColor: '#f54242' }}>
                        <StyledTableCell sx={{ color: 'white' }}>{createdAt}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{getGameDisplay()}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{betAmount}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{winAmount}</StyledTableCell>
                    </TableRow>
                )
            } else {
                return (
                    <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{betAt}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{getGameDisplay()}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{betAmount}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{winAmount}</StyledTableCell>
                    </StyledTableRow>
                )
            }
        }) ?? []
    }

    //API METHODS
    const getPayerBetHistory = () => {
        FeathersClient.create('get-player-bet-history', {
            company: userInfo?.company?._id,
            user: user._id,
        }, null, setAlertDialog, (response) => {
            setResult(response)

            //Get first page of users
            getList(0, true, values)
        })
    }
    const getList = (index, isRefresh = false, values) => {
        if ((index * rowsPerPage) >= list.length || isRefresh) {
            var query = {
                '$skip': (index * rowsPerPage),
                '$limit': rowsPerPage,
                'company': userInfo?.company?._id,
                'user': user._id,
                '$sort': {
                    'betAt': '-1',
                },
                '$populate': [
                    'company',
                    'companyGame',
                    'game',
                    'user',
                ],
            }

            //Filter by type
            if (values.game !== 999) {
                query['companyGame'] = values.game
            }

            //Filter by start date or end date
            if (values.startDate != null && values.endDate != null) {
                query['betAt'] = {

                    '$gte': (new Date((Utils.shared.toDateOnly(values.startDate)))).toISOString(),
                    '$lte': (new Date((Utils.shared.toEndOfDay(values.endDate)))).toISOString(),
                }
            } else if (values.startDate != null) {
                query['betAt'] = {
                    '$gte': (new Date((Utils.shared.toDateOnly(values.startDate)))).toISOString(),
                }
            } else if (values.endDate != null) {
                query['betAt'] = {
                    '$lte': (new Date((Utils.shared.toEndOfDay(values.endDate)))).toISOString(),
                }
            }

            FeathersClient.find('bets', {
                query: query
            }, setAlertDialog, (response) => {
                let bets = response?.data ?? []
                if (isRefresh) {
                    setList([...[], ...bets])
                    listRef.current = [...[], ...bets]

                    //Save current search criteris for socket checking
                    setValues(values => ({
                        ...values,
                        currentSearchGame: values.game,
                        currentSearchStartDate: values.startDate,
                        currentSearchEndDate: values.endDate,
                    }))
                } else {
                    setList(list => [...list, ...bets])
                    listRef.current = [...list, ...bets]
                }

                setListPaginationData(response)
                listPaginationDataRef.current = response

                setPage(index)
            })
        } else {
            setPage(index)
        }
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('bets', 'created', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === userInfo?.company?._id) &&
                (data?.user?._id === userInfo?._id) &&
                shouldIncludeRecordFromSocket(data)) {
                setList([data, ...listRef.current])
                listRef.current = [data, ...listRef.current]

                setListPaginationData({
                    ...listPaginationDataRef.current,
                    total: listPaginationDataRef.current.total + 1,
                })
                listPaginationDataRef.current.total = listPaginationDataRef.current.total + 1
            }
        })

        FeathersClient.listen('bets', 'patched', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                let tempList = [...listRef.current]
                tempList[index] = data
                listRef.current = tempList
                setList(tempList)
            }
        })

        FeathersClient.listen('bets', 'removed', 'customer', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)
            setList(tempList)
            listRef.current = tempList

            setListPaginationData({
                ...listPaginationDataRef.current,
                total: listPaginationDataRef.current.total - 1,
            })
            listPaginationDataRef.current.total = listPaginationDataRef.current.total - 1
        }
    }
    const shouldIncludeRecordFromSocket = (data) => {
        //Check if matches user with current search criteria
        //Check if matches bank if any
        if ((valuesRef.current.currentSearchGame?.length ?? 0) > 0) {
            if ((valuesRef.current.currentSearchGame !== 999)) {
                if ((data?.companyGame?._id !== valuesRef.current.currentSearchGame)) {
                    console.log('Bank not match')
                    return false
                }
            }
        }

        //Check if is after start date
        let betAt = new Date(data?.betAt).getMilliseconds()
        if (valuesRef.current.currentSearchStartDate && valuesRef.current.currentSearchEndDate) {
            //Start date and end date has values
            let startDate = new Date((Utils.shared.toDateOnly(valuesRef.current.currentSearchStartDate))).getMilliseconds()
            let endDate = new Date((Utils.shared.toEndOfDay(valuesRef.current.currentSearchEndDate))).getMilliseconds()

            if ((betAt < startDate) ||
                (betAt > endDate)) {
                console.log('Start and end date not match')
                return false
            }
        } else if (valuesRef.current.currentSearchStartDate) {
            let startDate = new Date((Utils.shared.toDateOnly(valuesRef.current.currentSearchStartDate))).getMilliseconds()
            if (betAt < startDate) {
                console.log('Start date not match')
                return false
            }
        } else if (valuesRef.current.currentSearchEndDate) {
            let endDate = new Date((Utils.shared.toEndOfDay(valuesRef.current.currentSearchEndDate))).getMilliseconds()
            if (betAt > endDate) {
                console.log('End date not match')
                return false
            }
        }

        return true
    }

    //COMPONENT METHODS
    function DetailRow({ title, value }) {
        return (
            <Stack direction='row' spacing='0px' sx={{ fontSize: '12px', color: Utils.shared.getColorByMode({lightColor: null, darkColor: 'white'}) }}>
                <div>{`${title}:`}</div>
                <div><b>{value}</b></div>
            </Stack>
        )
    }

    useEffect(() => {
        if (user) {
            getPayerBetHistory()
            startSocket()
        }
    }, [])

    useEffect(() => {
        valuesRef.current = values
    }, [values])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <Dropdown title='Game' value={values.game} placeholder={'Filter by game'} items={result?.companyGames} onSelectChanged={(value) => {
                        setValues(values => ({
                            ...values,
                            game: value,
                        }))
                    }} />
                    <BaseDateTimePicker key='fromDatePicker' title='Start Date' value={values.startDate} placeholder="dd/mm/yyyy" type='date' onTextChanged={(value) => {
                        setValues(values => ({ ...values, startDate: value }))
                    }} />
                    <BaseDateTimePicker key='toDatePicker' title='End Date' value={values.endDate} type='date' onTextChanged={(value) => {
                        setValues(values => ({ ...values, endDate: value }))
                    }} />
                    <BaseButton title='Search' onTap={() => {
                        let startDate = new Date(values.startDate)
                        let endDate = new Date(values.endDate)

                        if (Utils.shared.getWeeksDiff(startDate, endDate) > 2) {
                            setAlertDialog(alertDialog => ({
                                ...alertDialog,
                                description: 'Only maximum 2 weeks range allowed',
                                isOpen: true,
                            }))
                        } else {
                            getList(0, true, values)
                        }
                    }} />
                    <Stack direction='column' spacing='5px' sx={{ overflowX: 'scroll' }}>
                        <Divider />
                        <Stack direction='row' spacing='5px' sx={{ alignItems: 'center' }}>
                            <DetailRow title='Total Bet Count' value={`${listPaginationData?.total ?? 0}`} />
                            <Divider orientation='vertical' flexItem={true} />
                            <DetailRow title='Total Bet Amount' value={`${Utils.shared.getAmountString(listPaginationData?.totalBetAmount ?? 0)}`} />
                            <Divider orientation='vertical' flexItem={true} />
                            <DetailRow title='Total Win Amount' value={`${Utils.shared.getAmountString(listPaginationData?.totalWinAmount ?? 0)}`} />
                            <Stack direction='row' spacing='15px' sx={{ paddingLeft: '10px' }}>
                                <BaseButton title='Clear For Withdraw' onTap={() => {
                                    FeathersClient.create('clear-for-withdraw', {
                                        user: user?._id,
                                    }, null, setAlertDialog)
                                }} />
                                <BaseButton title='Clear Rollover' onTap={() => {
                                    FeathersClient.create('clear-rollover', {
                                        user: user?._id,
                                    }, null, setAlertDialog)
                                }} />
                                <BaseButton title='Clear Max Withdraw' onTap={() => {
                                    FeathersClient.create('clear-max-withdraw', {
                                        user: user?._id,
                                    }, null, setAlertDialog)
                                }} />
                            </Stack>
                        </Stack>
                        <Divider />
                    </Stack>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Date/Time</StyledTableCell>
                                        <StyledTableCell>Game</StyledTableCell>
                                        <StyledTableCell>Bet</StyledTableCell>
                                        <StyledTableCell>Win</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                        <StyledTableCell component="th" scope="row" align="center" colSpan={4}>
                                            No Record Found
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            count={getTotal()}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            onPageChange={(event, newPage) => {
                                getList(newPage, false, values)
                            }}
                            sx={{
                                backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#2f3539' }),
                                color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
                                svg: {
                                    color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })
                                }
                            }}
                        />
                    </Paper>
                </Stack>
            </div>
        )
    } else {
        return null
    }
}