import { Divider, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import BaseDateTimePicker from "../../components/datePicker/datePicker"
import Dropdown from "../../components/dropdown/dropdown"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import useScreenType from "react-screentype-hook"
import { StyledTableCell, StyledTablePagination, StyledTableRow } from "../../components/dataGrid/tableGrid"

export default function CustomerTransactionList({ user }) {
    //Pagination Constants
    const rowsPerPage = 20

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const screenType = useScreenType()

    //States
    const [values, setValues] = useState({
        type: 999,
        currentSearchType: 999,
        startDate: null,
        currentSearchStartDate: null,
        endDate: null,
        currentSearchEndDate: null,
    })
    const [page, setPage] = useState(0)
    const [list, setList] = useState([])
    const [listPaginationData, setListPaginationData] = useState(null)
    const [result, setResult] = useState(null)

    //Ref
    const listPaginationDataRef = useRef(listPaginationData)
    const listRef = useRef(list)
    const valuesRef = useRef(values)

    //DATA GRID METHODS
    const getTotal = () => {
        return listPaginationData?.total ?? 0
    }
    const getRows = () => {
        return list?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
            let updatedAt = row?.updatedAt ? Utils.shared.convertDateToString(Date.parse(row?.updatedAt), Constants.date_format_2) : ''
            let transactionId = row?.transactionId
            let rebateId = row?.rebateId
            let remarks = row?.remarks
            let remarksOther = row?.remarksOther ?? ''

            var transactionTypeDisplay = ''
            let transactionType = result?.transactionTypes?.find((e) => e.key === row?.type).value ?? '-'
            var appendText = '';

            switch (row?.type) {
                case Constants.transactionTypeBonus:
                    //Bonus
                    let bonusName = row?.bonus?.name ?? ''

                    if (bonusName.length > 0) {
                        appendText = bonusName
                    } else if (remarks === Constants.transactionRemarksOther && remarksOther.length > 0) {
                        appendText = remarksOther
                    }
                    break
                case Constants.transactionTypeManual:
                    //Manual
                    switch (row?.amountType) {
                        case 1:
                            appendText = 'ADD'
                            break
                        case 2:
                            appendText = 'DEDUCT'
                            break
                        default:
                            break
                    }
                    break
                case Constants.transactionTypeRebate:
                    //Rebate
                    if (rebateId) {
                        appendText = rebateId
                    }
                default:
                    let index = result?.transferMethods?.findIndex((e) => e.key === row?.transferMethod) ?? -1
                    if (index > -1) {
                        let transferMethod = result?.transferMethods[index]?.value ?? '-'
                        if (transferMethod.length > 0) {
                            appendText = transferMethod
                        }
                    }
            }

            if (appendText.length > 0) {
                transactionTypeDisplay = `${transactionType}\n(${appendText})`
            } else {
                transactionTypeDisplay = transactionType
            }

            let amount = Utils.shared.getAmountString(row?.amount, row?.currency)

            //Row Color
            if ((row?.type === 2 || row?.type === 6 || row?.type === 10) ||
                (row?.type === 4 && (row?.amount?.isNegative ?? false))) {
                return (
                    <TableRow key={`row${index}`} hover={true} sx={{ backgroundColor: '#f54242' }}>
                        <StyledTableCell sx={{ color: 'white' }}>{updatedAt}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{transactionId}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{transactionTypeDisplay}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{amount}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'white' }}>{remarksOther}</StyledTableCell>
                    </TableRow>
                )
            } else {
                return (
                    <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{updatedAt}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{transactionId}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{transactionTypeDisplay}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{amount}</StyledTableCell>
                        <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{remarksOther}</StyledTableCell>
                    </StyledTableRow>
                )
            }
        }) ?? []
    }

    //API METHODS
    const getPlayerTransactionListing = () => {
        FeathersClient.create('get-player-transaction-listing', {
            company: userInfo?.company?._id,
            user: user._id,
        }, null, setAlertDialog, (response) => {
            setResult(response)

            //Get first page of users
            getTransactionList(0, true, values)
        })
    }
    const getTransactionList = (index, isRefresh = false, values) => {
        if ((index * rowsPerPage) >= list.length || isRefresh) {
            var query = {
                '$skip': (index * rowsPerPage),
                '$limit': rowsPerPage,
                'company': userInfo?.company?._id,
                'user': user._id,
                'status': Constants.transactionStatusApproved,
                '$sort': {
                    'updatedAt': '-1',
                },
            }

            //Filter by type
            if (values.type !== 999) {
                query['type'] = values.type
            }

            //Filter by start date or end date
            if (values.startDate != null && values.endDate != null) {
                query['updatedAt'] = {

                    '$gte': (new Date((Utils.shared.toDateOnly(values.startDate)))).toISOString(),
                    '$lte': (new Date((Utils.shared.toEndOfDay(values.endDate)))).toISOString(),
                }
            } else if (values.startDate != null) {
                query['updatedAt'] = {
                    '$gte': (new Date((Utils.shared.toDateOnly(values.startDate)))).toISOString(),
                }
            } else if (values.endDate != null) {
                query['updatedAt'] = {
                    '$lte': (new Date((Utils.shared.toEndOfDay(values.endDate)))).toISOString(),
                }
            }

            FeathersClient.find('transactions', {
                query: query
            }, setAlertDialog, (response) => {
                let transactions = response?.data ?? []
                if (isRefresh) {
                    setList([...[], ...transactions])
                    listRef.current = [...[], ...transactions]

                    //Save current search criteris for socket checking
                    setValues(values => ({
                        ...values,
                        currentSearchType: values.type,
                        currentSearchStartDate: values.startDate,
                        currentSearchEndDate: values.endDate,
                    }))
                } else {
                    setList(list => [...list, ...transactions])
                    listRef.current = [...list, ...transactions]
                }

                setListPaginationData(response)
                listPaginationDataRef.current = response

                setPage(index)
            })
        } else {
            setPage(index)
        }
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('transactions', 'created', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === userInfo?.company?._id) &&
                (data?.user?._id === userInfo?._id) &&
                shouldIncludeRecordFromSocket(data)) {
                setList([data, ...listRef.current])
                listRef.current = [data, ...listRef.current]

                setListPaginationData({
                    ...listPaginationDataRef.current,
                    total: listPaginationDataRef.current.total + 1,
                })
                listPaginationDataRef.current.total = listPaginationDataRef.current.total + 1
            }
        })

        FeathersClient.listen('transactions', 'patched', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                var tempList = [...listRef.current]
                tempList[index] = data
                listRef.current = tempList
                setList(tempList)
            }
        })

        FeathersClient.listen('transactions', 'removed', 'customer', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)
            setList(tempList)
            listRef.current = tempList

            setListPaginationData({
                ...listPaginationDataRef.current,
                total: listPaginationDataRef.current.total - 1,
            })
            listPaginationDataRef.current.total = listPaginationDataRef.current.total - 1
        }
    }
    const shouldIncludeRecordFromSocket = (data) => {
        //Check if matches user with current search criteria
        //Check if matches status
        if (data?.status !== Constants.transactionStatusApproved) {
            console.log('Status not match')
            return false
        }

        //Check if matches type if any
        if ((valuesRef.current.currentSearchType?.length ?? 0) > 0) {
            if ((valuesRef.current.currentSearchType !== 999)) {
                if ((data?.type !== valuesRef.current.currentSearchType)) {
                    console.log('Type not match')
                    return false
                }
            }
        }

        //Check if is after start date
        let createdAt = new Date(data?.createdAt).getMilliseconds()
        if (valuesRef.current.currentSearchStartDate && valuesRef.current.currentSearchEndDate) {
            //Start date and end date has values
            let startDate = new Date((Utils.shared.toDateOnly(valuesRef.current.currentSearchStartDate))).getMilliseconds()
            let endDate = new Date((Utils.shared.toEndOfDay(valuesRef.current.currentSearchEndDate))).getMilliseconds()

            if ((createdAt < startDate) ||
                (createdAt > endDate)) {
                console.log('Start and end date not match')
                return false
            }
        } else if (valuesRef.current.currentSearchStartDate) {
            let startDate = new Date((Utils.shared.toDateOnly(valuesRef.current.currentSearchStartDate))).getMilliseconds()
            if (createdAt < startDate) {
                console.log('Start date not match')
                return false
            }
        } else if (valuesRef.current.currentSearchEndDate) {
            let endDate = new Date((Utils.shared.toEndOfDay(valuesRef.current.currentSearchEndDate))).getMilliseconds()
            if (createdAt > endDate) {
                console.log('End date not match')
                return false
            }
        }

        return true
    }

    //COMPONENT METHODS
    function DetailRow({ title, value }) {
        return (
            <Stack direction={screenType.isMobile ? 'column' : 'row'} spacing={screenType.isMobile ? '0px' : '5px'} sx={{ fontSize: '12px', color: Utils.shared.getColorByMode({lightColor: 'black', darkColor: 'white'}) }}>
                <div>{`${title}:`}</div>
                <div><b>{value}</b></div>
            </Stack>
        )
    }

    useEffect(() => {
        if (user) {
            getPlayerTransactionListing()
            startSocket()
        }
    }, [])

    useEffect(() => {
        valuesRef.current = values
    }, [values])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <Dropdown title='Type' value={values.type} placeholder={'Filter by type'} items={result?.transactionTypes} onSelectChanged={(value) => {
                        setValues(values => ({
                            ...values,
                            type: value,
                        }))
                    }} />
                    <BaseDateTimePicker key='fromDatePicker' title='Start Date' value={values.startDate} placeholder="dd/mm/yyyy" type='date' onTextChanged={(value) => {
                        setValues(values => ({ ...values, startDate: value }))
                    }} />
                    <BaseDateTimePicker key='toDatePicker' title='End Date' value={values.endDate} type='date' onTextChanged={(value) => {
                        setValues(values => ({ ...values, endDate: value }))
                    }} />
                    <BaseButton title='Search' onTap={() => {
                        getTransactionList(0, true, values)
                    }} />
                    <Stack direction='column' spacing='5px' sx={{ overflowX: 'scroll' }}>
                        <Divider />
                        <Stack direction='row' spacing='5px'>
                            <DetailRow title='Total Transaction' value={`${listPaginationData?.total ?? 0}`} />
                            <Divider orientation='vertical' flexItem={true} />
                            <DetailRow title='Total Amount' value={`${Utils.shared.getAmountString(listPaginationData?.totalAmount ?? 0)}`} />
                            <Divider orientation='vertical' flexItem={true} />
                            <DetailRow title='Total Deposit' value={`${Utils.shared.getAmountString(listPaginationData?.totalDeposit ?? 0)}`} />
                            <Divider orientation='vertical' flexItem={true} />
                            <DetailRow title='Total Withdraw' value={`${Utils.shared.getAmountString(listPaginationData?.totalWithdraw ?? 0)}`} />
                            <Divider orientation='vertical' flexItem={true} />
                            <DetailRow title='Total Win/Loss' value={`${Utils.shared.getAmountString(listPaginationData?.totalWinLoss ?? 0)}`} />
                        </Stack>
                        <Divider />
                    </Stack>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Date/Time</StyledTableCell>
                                        <StyledTableCell>ID</StyledTableCell>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>Amount</StyledTableCell>
                                        <StyledTableCell>Remarks</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                        <StyledTableCell component="th" scope="row" align="center" colSpan={5}>
                                            No Record Found
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            count={getTotal()}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            onPageChange={(event, newPage) => {
                                getTransactionList(newPage, false, values)
                            }}
                            sx={{
                                backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#2f3539' }),
                                color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
                                svg: {
                                    color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })
                                }
                            }}
                        />
                    </Paper>
                </Stack>
            </div>
        )
    } else {
        return null
    }
}