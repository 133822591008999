import React from "react"
import Button from '@mui/material/Button'
import { styled } from "@mui/material/styles"
import Utils from "../../utils/utils"

export default function BaseButton({ title, icon = null, color = '#68bc7a', textColor = '#ffffff', type = 'button', disabled = false, fullWidth = true, sx = null, hoverColor = null, onTap }) {
  const autoHoverColor = Utils.shared.brighten(color, 1.1)
  const disabledColor = Utils.shared.brighten(color, 0.6)

  const StyledButton = styled(Button)(() => ({
    ':hover': {
      backgroundColor: hoverColor ?? autoHoverColor,
      color: textColor,
    },
    ':disabled': {
      backgroundColor: disabledColor,
      color: textColor,
    },
    color: textColor,
    backgroundColor: color,
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    fontSize: '12px',
    svg: {
      color: 'white'
    }
  }))

  return (
    <StyledButton sx={sx} variant='contained' size='small' type={type} startIcon={icon} fullWidth={fullWidth} disabled={disabled} onClick={onTap}>
      <b>{title}</b>
    </StyledButton>
  );
}