import { useGet } from "figbird"
import { useContext } from "react"
import { UserInfoContext } from "../App"

export function useCompanyInfo() {
    //Contexts
    const { userInfo } = useContext(UserInfoContext)
    const { data } = useGet('companies', userInfo?.company?._id)

    return data ?? userInfo?.company
}