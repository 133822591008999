import React from "react"
import { TextField } from '@mui/material'
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers'
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Utils from "../../utils/utils";
import { parseISO } from "date-fns";

function BaseDateTimePicker({
    title,
    value,
    helperText,
    titleColor = Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
    /**
    * date | time | datetime | datetime-local
    */
    type = 'date',
    inputFormat = 'dd/MM/yyyy',
    showError = false,
    errorText = '',
    disabled = false,
    onTextChanged,
    onSubmit = null,
}) {
    const getPicker = () => {
        let currentValue = value ? new Date(value) : null

        switch (type) {
            case 'date':
                return (
                    <DatePicker
                        label={title}
                        value={currentValue}
                        onChange={(newValue) => {
                            onTextChanged(newValue)
                        }}
                        type={type}
                        disabled={disabled}
                        inputFormat={inputFormat}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (onSubmit) {
                                    onSubmit()
                                }
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                InputLabelProps: {
                                    style: {
                                        color: titleColor,
                                    },
                                    shrink: true,
                                },
                                FormHelperTextProps: {
                                    sx: {
                                        color: showError ? 'red' : titleColor
                                    }
                                },
                                inputProps: {
                                    color: titleColor,
                                },
                                helperText: showError ? errorText : helperText,
                                sx: {
                                    "& .MuiOutlinedInput-root": {
                                        color: titleColor,
                                        "&.Mui-focused fieldset": {
                                            color: titleColor,
                                            borderColor: titleColor
                                        },
                                        "&:hover fieldset": {
                                            color: titleColor,
                                            borderColor: Utils.shared.getColorByMode({ lightColor: 'gray', darkColor: '#999999' })
                                        },
                                        "&.Mui-disabled fieldset": {
                                            color: titleColor,
                                            borderColor: Utils.shared.getColorByMode({ lightColor: 'gray', darkColor: '#999999' }),
                                        },
                                        "& fieldset": {
                                            color: titleColor,
                                            borderColor: titleColor
                                        },
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: titleColor,
                                    },
                                    svg: {
                                        color: titleColor
                                    },
                                },
                            },
                        }}
                    />
                )
            case 'time':
                return (
                    <TimePicker
                        label={title}
                        value={currentValue}
                        onChange={(newValue) => {
                            onTextChanged(newValue)
                        }}
                        type={type}
                        disabled={disabled}
                        //inputFormat={inputFormat}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (onSubmit) {
                                    onSubmit()
                                }
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                InputLabelProps: {
                                    style: {
                                        color: titleColor,
                                    },
                                    shrink: true,
                                },
                                FormHelperTextProps: {
                                    sx: {
                                        color: showError ? 'red' : titleColor
                                    }
                                },
                                inputProps: {
                                    color: titleColor,
                                },
                                helperText: showError ? errorText : helperText,
                                sx: {
                                    "& .MuiOutlinedInput-root": {
                                        color: titleColor,
                                        "&.Mui-focused fieldset": {
                                            color: titleColor,
                                            borderColor: titleColor
                                        },
                                        "&:hover fieldset": {
                                            color: titleColor,
                                            borderColor: Utils.shared.getColorByMode({ lightColor: 'gray', darkColor: '#999999' })
                                        },
                                        "&.Mui-disabled fieldset": {
                                            color: titleColor,
                                            borderColor: Utils.shared.getColorByMode({ lightColor: 'gray', darkColor: '#999999' }),
                                        },
                                        "& fieldset": {
                                            color: titleColor,
                                            borderColor: titleColor
                                        },
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: titleColor,
                                    },
                                    svg: {
                                        color: titleColor
                                    },
                                },
                            },
                        }}
                    />
                )
            default:
                return (
                    <DateTimePicker
                        label={title}
                        value={currentValue}
                        onChange={(newValue) => {
                            onTextChanged(newValue)
                        }}
                        type={type}
                        disabled={disabled}
                        inputFormat={inputFormat}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (onSubmit) {
                                    onSubmit()
                                }
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                InputLabelProps: {
                                    style: {
                                        color: titleColor,
                                    },
                                    shrink: true,
                                },
                                FormHelperTextProps: {
                                    sx: {
                                        color: showError ? 'red' : titleColor
                                    }
                                },
                                inputProps: {
                                    color: titleColor,
                                },
                                helperText: showError ? errorText : helperText,
                                sx: {
                                    "& .MuiOutlinedInput-root": {
                                        color: titleColor,
                                        "&.Mui-focused fieldset": {
                                            color: titleColor,
                                            borderColor: titleColor
                                        },
                                        "&:hover fieldset": {
                                            color: titleColor,
                                            borderColor: Utils.shared.getColorByMode({ lightColor: 'gray', darkColor: '#999999' })
                                        },
                                        "&.Mui-disabled fieldset": {
                                            color: titleColor,
                                            borderColor: Utils.shared.getColorByMode({ lightColor: 'gray', darkColor: '#999999' }),
                                        },
                                        "& fieldset": {
                                            color: titleColor,
                                            borderColor: titleColor
                                        },
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: titleColor,
                                    },
                                    svg: {
                                        color: titleColor
                                    },
                                },
                            },
                        }}
                    />
                )
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {getPicker()}
        </LocalizationProvider>
    )
}

export default BaseDateTimePicker;